.carousel-indicators.carousel-indicators-circle {
	bottom:0;
	li {
		height: 20px;
		max-width: 20px !important;
		border-radius:50%;
		box-shadow: none;
		border: 1px solid #ccc !important;
		background-color: rgba(255,255,255,0) !important;
	}

	.active{
		background-color: #ccc !important;
	}

}
.carousel-control-prev, .carousel-control-next{
	opacity:1;
	width:initial;
}
.carousel-control-next-icon.carousel-control-next-icon-gray {
	background-image: $carousel-control-next-icon-bg-gray
}
.carousel-control-prev-icon.carousel-control-prev-icon-gray {
	background-image: $carousel-control-prev-icon-bg-gray;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
	width:40px;
	height:40px;
}

[class^="carousel-control-follow"], [class*=" carousel-control-follow"] {
	width: 50%;
	cursor: ew-resize;
}
.carousel-control-prev.carousel-control-follow {
  cursor: $carousel-control-prev-icon-bg 16 16, pointer;
}
.carousel-control-next.carousel-control-follow {
  cursor: $carousel-control-next-icon-bg 16 16, pointer;
}
.carousel-control-prev.carousel-control-follow-gray {
  cursor: $carousel-control-prev-icon-bg-gray 16 16, pointer;
}
.carousel-control-next.carousel-control-follow-gray {
  cursor: $carousel-control-next-icon-bg-gray 16 16, pointer;
}
.carousel-control-prev.carousel-control-follow-blue {
  cursor: $carousel-control-prev-icon-bg-blue 16 16, pointer;
}
.carousel-control-next.carousel-control-follow-blue {
  cursor: $carousel-control-next-icon-bg-blue 16 16, pointer;
}

.carousel-indicators.carousel-indicators-simple-dots {
	position: relative;
	bottom: 0;
	margin: auto 0;

	li {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #D9D9D9;
		box-shadow: none;
		margin: 0 5px;

		&.active {
			background-color: #3171B8;
		}
	}
}
::-webkit-scrollbar {
	width: rem(14);
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0,0,0,0.15);
	background-color:#eee;
}

::-webkit-scrollbar-thumb {
	background-color: #a8b1bb;
	border-radius:rem(12);
	border: rem(3) solid #eee; 
}
#message-popup{
	background-color:#fff;
	border:1px solid #ccc;
	border-radius:5px;
	bottom:0;
	height:130px;
	left:0;
	margin:0 auto;
	min-width:335px;
	padding:2.5rem 2rem;
	position:absolute;
	right:0;
	top:35%;
	width:25%;
	z-index:1000;
}
#message-popup.profile-success{
	top:10%;
}
#message-popup p{
	margin:0;
}
#message-popup span:nth-child(2){
	margin:0 0 0 10px;
}
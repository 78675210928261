/* For dimming an area, add and remove via JS */
.dimmable {
	position: relative;
}
.dimmable:after{
	transition: all .2s linear;
	background-color: #000;
	content: "";
	opacity: 0;
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.dimmable.darken:after{
	opacity: 0.6;
}
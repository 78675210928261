/* Tables or iFrames that can't fit, need to scroll */
.table-wrapper{
	overflow:auto;
	-webkit-overflow-scrolling:touch;
}
.table-wrapper table{
	min-width:610px;
}
.iframe-wrapper, .scrolling-wrapper{
	overflow:auto;
	width: 100%;
	-webkit-overflow-scrolling:touch;
}

.bg-table-green-accent {
	background-color: $table-bg-green-accent;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .table#{$infix}-wrapper {
    	overflow:auto !important;
		-webkit-overflow-scrolling:touch !important; 
    }
}
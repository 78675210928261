@import "../custom";

// Override the Bootstrap font "sans-serif" to the Putnam body font
button,
input,
optgroup,
select,
textarea {
	font-family: $font-family-sans-serif;
}

/*  For browsers that support variable fonts,
	this reduces file size by 42% (88kb for all the woff2 font weights vs 51kb for normal and italic variable fonts)
	and gives us 2,000 font-weight options vs the
	original four we had.
 */
@supports ( font-variation-settings: normal ) {
	body,
	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: 'Source Sans Variable', sans-serif;
	}
}

body,
button {
	text-rendering: geometricPrecision;
}

p {
	margin-bottom: 0.625rem; // 10px
	a {	font-weight: $font-weight-bold; }
}

h1, .h1 {
	margin: 0.4em 0 0.6em;
	line-height: rem(35);
}
h2, .h2 {
	margin: 0.5em 0 .66em;
	line-height: rem(32);
	font-weight: $font-weight-bold;
}
h3, .h3 {
	margin: 0.6em 0;
	line-height: rem(29);
	font-weight: $font-weight-light;
}
h4, .h4 {
	margin: 0.6em 0;
	line-height: rem(25);
	font-weight: $font-weight-bold;
}
h5, .h5 {
	margin: 0.6em 0;
	line-height: rem(22);
	font-weight: $font-weight-bold;
}
h6, .h6 {
	margin: 0.6em 0;
	line-height: rem(20);
	font-weight: $font-weight-bold;
}

pre {
	line-height: 1;
}

.content-header #pricingHeader .toggle-arrow[aria-expanded="false"]:after {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: icomoon!important;
    content: "\e642";

    @include media-breakpoint-up(md){
    	display: none;
    }
}

.content-header #pricingHeader .toggle-arrow[aria-expanded="true"]:after {
    cursor: pointer;
    font-size: 1.5rem;
    font-family: icomoon!important;
    content: "\e641";

    @include media-breakpoint-up(md){
    	display: none;
    }
}

@include media-breakpoint-up(md) {
	h1,
	.h1 {
		line-height: rem(46);
		font-size: $font-size-h1-sm;
	}
	.display-1 { font-size: 3.333rem; }
	.display-2 { font-size: 3rem; }
	.display-3 { font-size: 2.667rem; }
	.display-4 { font-size: 2.333rem; }
}

strong, b {	font-weight: $font-weight-bold; }

sup {
	top: -0.6em;
	font-size: 50%;
}
.sup-lg {
	font-size: 70% !important;
}

a.no-underline:hover {
	text-decoration: none;
}

a.inactive:hover {
	text-decoration: none;
	color: inherit;
	cursor: default;
}

p a,
p a:visited,
th,
.bold {	font-weight: $font-weight-bold; }

a[href^=tel] {
	line-height: inherit;
	text-decoration: none !important;
	color: inherit;
}

.putnamicon-morningstar {
    color: $morningstar;

    &.morningstar-orange
    {
    	color: $morningstarOrange;
    }
}

.text-300 {
	font-weight: 300 !important;
}
.text-400 {
	font-weight: 400 !important;
}
.text-600 {
	font-weight: 600 !important;
}
.text-700 {
	font-weight: 700 !important;
}
.text-800 {
	font-weight: 800 !important;
}

.text-color-body {
	color: $body-color;
}

.glyph-link {
	position: relative;
	padding-left: 1.3em;
	
	
	.putnamicon {
		position: absolute;
		left: 0;
		top: 2px;
	}
	
	&.glyph-xs {
		padding-left: 0.9em;

		.putnamicon {
			font-size: 0.6em;
			top: 5px;
		}
	}

	&.glyph-sm {
		padding-left: 1.1em;

		.putnamicon {
			font-size: 0.8em;
			top: 3px;
		}
	}

	&.glyph-lg {
		padding-left: 1.7em;

		.putnamicon {
			font-size: 1.3em;
			top: -1px;
		}
	}

	&.collapsed {

		.putnamicon-minus {

			&:before {
			    content: "\e621";
			}
		}		
	}

}

/* Webcast styles */
/* Example usage
	<#include '/include/content/webcasts/single-wide-band.ftl'>
 */

.webcast-separator {
	padding: 0 9px;

	@include media-breakpoint-up(xl) {
		padding: 0 15px;
	}

	@include media-breakpoint-down(md) {
		display: block;
		text-indent: -9999px;
		height: 0;
	}
}
.webcast-title-meta {
	@include media-breakpoint-up(lg) {
		margin-top: 3px;
	}
}
.webcast-callout-orange {
	color: #ff6600;
	display: inline;
}
#webcast-conference-call {
	border-bottom: 1px solid #CCC;
}
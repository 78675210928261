.modal {
	&.open {
		display: block;
	}
}

#image-modal .modal-dialog .modal-body {
	height: 100%;
	flex: 1;
}

.modal-vertical-center {
	display: flex !important;
	align-items: center;
}

.modal-title {
	margin-top: 0;
}

.modal-body-touch {
	-webkit-overflow-scrolling: touch;
}

#unified-login-modal {
	.modal-body {
		padding:0;
		background-color:#f5f5f5;
	}
}

.hide-modal-button {
	width: 26px;
	height: 26px;
	margin-right: -7px;

	.putnamicon-close {
		font-size: 0.7rem;
		line-height: 0;
		display: block;
	}
}

// Registration modal
#register-modal {
	.modal-content {
		background-color:#f5f5f5;
	}
	.modal-header {
		padding: 15px 30px;
		&.hide-title {
			padding: 10px 15px;
		}
	}
	.modal-body {
		padding: 30px;
	}
}
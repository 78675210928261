/* Auto Nav */
.auto-nav-xs ul,
.auto-nav-sm ul,
.auto-nav-md ul,
.auto-nav-lg ul{
	display: none;
}
.auto-nav h4, .auto-nav h5{
	cursor: pointer;
	cursor: hand;
}
@media (min-width: 575px){
	.auto-nav-xs ul{
		display:block;
	}
}
@media (min-width: 768px){
	.auto-nav-sm ul{
		display:block;
	}
}
@media (min-width: 992px){
	.auto-nav-md ul{
		display:block;
	}
}
@media (min-width: 992px){
	.auto-nav-lg ul{
		display:block;
	}
}

.faqs>ul>li {
	padding: 10px 0;
	width: 95%;
	border-top: dotted 1px #cccccc;
}
.faqs a {
	display: block;
}
.faqs .answer a {
    display: inline;
}	
.faqs .answer {
	display: none;
	list-style: none;
	margin: 0 !important;
	padding: 0 !important;
}
.answer-sibling li{
	list-style-type: disc;
}
.icon-shhome-faqs {
    background-position: -410px 0;
    margin-top: -10px;
}
.faqs .putnamicon {
	float:right;
	font-size: 20px;
	color: #000;
}
.no-bullet {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
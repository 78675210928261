.tableless{
	display:table;
	margin-bottom:1rem;
}
.table-header-group{
	display:table-header-group;
}
.table-row{
	display: table-row;
}
.table-body{
	display:table-row-group;
}
.table-header{
	border-bottom:1px solid #ccc;
	font-weight:600;
}
.table-data{
	border-top:1px solid #ccc;
}
.table-header, .table-data{
	display:table-cell;
	padding:0.75rem 0;
	position:relative;
	text-align:center;
	vertical-align:middle;
	width:auto;
}
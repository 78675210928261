.bc-playlist-sidecar {

	.bc-playlist-sidecar-main {
		margin-bottom: 5px;
		
		@include media-breakpoint-up( md ) {
			margin-bottom: 0;
			flex: 0 0 73.35%;
			max-width: 73.35%;
		}
		@include media-breakpoint-up( lg ) {
			flex: 0 0 73.75%;
			max-width: 73.75%;
		}
		@include media-breakpoint-up( xl ) {
			flex: 0 0 73.95%;
			max-width: 73.95%;
		}
	}

	.bc-playlist-sidecar-area {

		& > .row {

			.bc-playlist-sidecar-item {
				margin-bottom: 5px;
				margin-left: 5px;

				&:first-child {
					margin-left: 0;
				}

				@include media-breakpoint-down( sm ) {
					flex: 0 0 32.71%;
					max-width: 32.71%;
				}

				@include media-breakpoint-up( md ) {
					margin-left: 0;
					margin-bottom: 7px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.vjs-big-play-button {
					font-size: 1.5em;
					margin-left: -0.75em;
					margin-top: -0.75em;
					width: 1.5em;
					height: 1.5em;
					line-height: 1.5em;
				}
			}
			
			@include media-breakpoint-down( sm ) {
				flex-wrap: nowrap;
				overflow-x: auto;
			}
		}

		@include media-breakpoint-up( md ) {
			overflow-y: auto;
			overflow-x: hidden;
			flex: 0 0 26.65%;
			max-width: 26.65%;
			height: 280px;
		}
		@include media-breakpoint-up( lg ) {
			flex: 0 0 26.25%;
			max-width: 26.25%;
			height: 383px;
		}
		@include media-breakpoint-up( xl ) {
			flex: 0 0 26.05%;
			max-width: 26.05%;
			height: 459px;
		}
	}

}

/* Video Modal Share links */
.video-modal-share-links {

	.video-modal-share-text {
		vertical-align: 5px;
	}

	.putnamicon-link {
		font-size: 0.8em;
		vertical-align: 2px;
	}
	
	a.video-modal-share-link {
		transition: 0.3s opacity;
		padding: 0.2em;
		font-size: 1.5rem;

		&:link,
		&:visited {
			text-decoration: none;
			color: inherit;
			opacity: 1;
		}
		&:hover,
		&:active {
			opacity: 0.6;
		}
	}
}

/* New fancy ajax method of using simple brightcove video players */
.brightcove-video,
.brightcove-video-link,
.brightcove-image-link,
.brightcove-video-wrapper {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	background-color: #F1F1F1;

	&.bc-sidecar-image-link-active {
		outline: 5px solid #03C5D8;
		outline-offset: -5px;
		pointer-events: none;

		&::after {
			content: "SELECTED";
			position: absolute;
			top: 0;
			left: 0;
			background-color: #03C5D8;
			color: #000;
			font-weight: 800;
			padding: 5px 10px;
		}
		
		.vjs-big-play-button {
			display: none;
		}
	}

	button {
		cursor: pointer;
	}

	.video-js {
		position: absolute;
	}

	.vjs-big-play-button {
		position: absolute;
		font-size: 2em;
		background-color: rgba(0,0,0,0.5);
		top: 50%;
		left: 50%;
		margin-left: -1em;
		margin-top: -1em;
		width: 2em;
		height: 2em;
		line-height: 2em;
		border: none;
		border-radius: 50%;
		color: rgba(255,255,255,0.8);
		transition: background-color 0.2s ease;

		&:before {
			font-family: 'icomoon' !important;
			content: "\e940";
			text-align: center;
			position: absolute;
			top: 0;
			left: 1px;
			width: 100%;
			height: 100%;
			font-size: 0.75em;
		}
	}

	.vjs-mobile-load-button {
		position: absolute;
		bottom: 15%;
		right: 0;
		border: 0;
		text-align: right;
		text-transform: uppercase;
		padding: 1em 1.5em 1em 2em;
		font-size: 1.2em;
		font-weight: 700;
		background-color: rgba(255,255,255, 0.6);
		color: #333;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		.putnamicon-play {
			vertical-align: -1px;
		}
	}

	.vjs-control-text {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.bc-annotation {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.0;
		-webkit-transition: all 0.3s ease-out; 
		transition: all 0.3s ease-out;

		&:hover{
			opacity:0.1;
		}
	}
}
.brightcove-video-link {

	img {
		width: 100%;
	}

	&:hover {
		
		.vjs-big-play-button {
			background-color: rgba(0,0,0, .80);
			color: #FFF;
		}
	}
}



.sixteen-nine, .bc-playlist {
	position: relative;
}
.sixteen-nine:before, .bc-playlist:before {
	display: block;
	content: "";
	width: 100%;
	padding-top: 56.35%;
}
.bc-playlist:before{
	padding-top:67.9%;
}
.bc-playlist.bc-thumbs-bottom.bc-thumbs-grey-bg .vjs-playlist{
	background-color: #E7E8E9;
} 
.sixteen-nine .content, .bc-playlist .content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.bc-playlist.bc-thumbs-bottom .content{
	height:83%;
}

.video-js{
	width: 100% !important;
	height: 100% !important;
}
.video-js .vjs-tech{
	position: inherit !important;
}
.bc-no-playlist-text .vjs-playlist-name{
	display: none;
}
.bc-player-NkJFhf5dg_default .vjs-big-play-button,
.bc-player-88bcd190-20c5-425e-ba84-b3d408ddade4_default .vjs-big-play-button {
	font-size: 2em;
	background-color: rgba(0,0,0,0.5);
	top: 50%;
	left: 50%;
	margin-left: -1em;
	margin-top: -1em;
	width: 2em;
	height: 2em;
	line-height: 2em;
	border: none;
	border-radius: 50%;
	color: rgba(255,255,255,0.8);
}
.bc-player-NkJFhf5dg_default:active .vjs-big-play-button,
.bc-player-NkJFhf5dg_default:hover .vjs-big-play-button,
.bc-player-88bcd190-20c5-425e-ba84-b3d408ddade4_default:active .vjs-big-play-button,
.bc-player-88bcd190-20c5-425e-ba84-b3d408ddade4_default:hover .vjs-big-play-button {
	background-color: rgba(0,0,0, .85);
	color: #FFF;
}
.bc-iframe .vjs-playlist {
	height: auto;
}
.vjs-progress-control.vjs-control {
	position: absolute;
	width: 100%;
}
.bc-playlist .vjs-control-bar {
	background-color: rgba(89,93,97, .45);
}
time,
.bc-playlist.bc-thumbs-bottom .vjs-playlist-description,
.bc-playlist.bc-thumbs-bottom .vjs-playlist-ad-overlay {
	display: none !important;
}
.bc-playlist.bc-thumbs-bottom #vjs_video_3{
	position: relative;
	width:100%!important;
}
.bc-playlist.bc-thumbs-bottom .thumb-wrapper {
  width: 100%;
  overflow-x: auto;
}
.bc-playlist.bc-thumbs-bottom .vjs-playlist {
	position: relative;
	width: 170%;
	display: block;
	padding:6px 0px 0 6px;
}
.bc-playlist.bc-thumbs-bottom .vjs-playlist-item {
	width: 18.8%;
	margin-right:1.2%;
	display: inline-block;
	margin-bottom: 4px !important;
	height:auto;
}
.bc-playlist.bc-thumbs-bottom .vjs-playlist .vjs-playlist-thumbnail,
.bc-playlist.bc-thumbs-bottom .vjs-playlist .vjs-selected .vjs-playlist-thumbnail:before{
	height:100%;
	width:100%;	
}
.bc-playlist.bc-thumbs-bottom .vjs-playlist .vjs-selected .vjs-playlist-thumbnail:before {
	left: 0;
	top: 0;
	padding-top: 20%;
}
.bc-playlist.bc-thumbs-bottom .vjs-mouse.vjs-playlist .vjs-playlist-item:hover .vjs-playlist-thumbnail:before{
	display: none;
}
.vjs-playlist-now-playing:before {
	padding-top: 5% !important;
}

// Video Post
.video-post {
	border: 1px solid #CAD1D5;
	height: 100%;
}

.video-post-content {
	padding: 10px 15px;

	// >div:not( :last-child )
	// {
	// 	border-bottom: 1px dotted #333;
	// 	margin-bottom: 0.75rem;
	// 	padding-bottom: 0.75rem;
	// }
}

@media (max-width: 768px) {
	.video-post-content {
		height: 100%;
		margin-bottom: 18px;
	}
}

@media(min-width:375px){
	.bc-playlist.bc-thumbs-bottom .vjs-playlist{
		width:140%;
	}
}

@media(min-width:480px){
	.bc-playlist.bc-thumbs-bottom .vjs-playlist {
		width: 118%;
	}
}

@media(min-width:568px){
	.bc-playlist.bc-thumbs-bottom .vjs-playlist {
		width: 100%;
	}
}

@media(min-width:992px){
	.bc-playlist.bc-thumbs-bottom .thumb-wrapper{
		overflow:auto;
	}
	.bc-playlist.bc-thumbs-bottom .vjs-playlist {
		width: 100%;
		height:auto;
	}
}

@media(min-width:1200px){
	.bc-playlist.bc-thumbs-bottom:before {
		padding-top: 67.4%;
	}
	.bc-playlist.bc-thumbs-bottom .content{
		height:83.5%;
	}	
	.bc-playlist.bc-thumbs-bottom .vjs-playlist-item {
		margin-bottom: 0px !important;
	}
}

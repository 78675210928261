.nav-dark-wrapper {
	position: relative;

	&.empty {
		height: 20px;
		background: #373a3c;
	}

	@include media-breakpoint-between(md, md) {
		font-size: 0.9em;
	}

	@include media-breakpoint-up(md) {
		background: #373a3c;
	}

	@include media-breakpoint-down(sm) {

		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			height: 50px;
			background-color: #373a3c;
			z-index: 0;
		}
		&.empty::after {
			display: none;
		}
	}

	.navbar {
		z-index: 3;
		padding: 0;
		position: relative;

		> .container {

			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}

		&.no-links {
			@include media-breakpoint-up(md) {
				height: 5px;
			}
		}
	}

	.navbar-nav {

		@include media-breakpoint-down(sm) {

			> .nav-item > .nav-link {
				text-transform: uppercase;
			}

			.nav-link {
				color: #333;
				border-bottom: 1px solid #FFF;
				padding: 0.84em 1em;

				&.active {
					background-color: #FFF;
					color: #333;
					border-bottom: 1px solid #ECF0F1;
					
					@include hover-focus {
						color: #333;
					}
				}
				
				@include hover-focus {
					color: #333;
				}
			}
		}

		@include media-breakpoint-up(md) {

			.nav-link {
				color: #FFF;
				padding: 15px 14px;
				height: 100%;

				@include hover-focus {
					background-color: #efefef;
					color: #333;
				}

				&.active {
					background-color: #FFF;
					color: #333;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			margin-left: 15px;

			.nav-link {
				padding: 15px;
			}
		}

		.nav-item {
			padding: 0;
			position: relative;

			@include media-breakpoint-up(md) {
				
				&.selected{

					a {
					    color: #253773 !important;
					    background: #FFFFFF !important;
					}
				}
			
				border-right: 1px solid #666;
				margin-left: 0;

				&:hover,
				&.hover {
					.nav-link {
						background-color: #efefef !important;
						color: #333 !important;
					}
					.sub-nav {
						display: block;

						&.sub-sub-nav.collapse {
							display: none;

							&.show {
								display: block;
							}
						}
					}
				}

				&.ignore .nav-link,
				&.ignore .nav-link {
					background-color: transparent !important;
					color: #FFF !important;
				}

				&:first-child {
					border-left: 1px solid #666;
				}
			}

			&.client-accounts > a {
				@include media-breakpoint-up(md){
					color: #fff;
					background: #30476f;
				}
			}
		}
	}	

	.navbar-toggler,
	.navbar-meatball {
		padding: 0.75rem 15px;
		width: 100%;
		text-align: left;
		border: 0;
		text-decoration: none;

		&.bordered {
			padding: 0.93rem 15px 0.93rem 16px;
			border-right: 1px solid #818181;

			@include media-breakpoint-between(sm, sm) {
				border-left: 1px solid #818181;
			}

			.putnamicon {
				font-size: 1.1rem;
			}
		}

		.putnamicon {
			color: #fff;
			font-size: 1.4rem;
		}
	}

	.navbar-meatball {
		padding: 0.75rem 14px 0.63rem;
		border-right: 0;
		border-left: 1px solid #818181;

		@include media-breakpoint-between(sm, sm) {
			border-right: 1px solid #818181;
		}
	}

	.mobile-site-logo {
		padding: 0.55rem 0.6rem 0.55rem 0;

		img {
			width: 30px;
		}
	}

	.logo-mobile-subhead {
		text-decoration: none;
		text-transform: uppercase;
		color: #FFF;
		vertical-align: 0px;
	}

	.sub-nav-wrapper {
		width: 100%;
		border-top: 1px solid #ccc !important;
		position: relative;
		z-index: 3;

		@include media-breakpoint-down(sm) {
			background-color: #ECF0F1;
		}
	}

	.sub-nav {
		list-style: none;
		padding-left: 0;
		background-color: #FFF;

		@include media-breakpoint-up(md) {
			// position: absolute;
			left: 0;
			z-index: 999;
			width: 100%;
			background-color: #efefef;
			border-top: 1px solid #ccc;
			box-shadow: 0 1px 1px #aaa;

			.nav-item {
				border-left: 0 !important;
				border-right: 0 !important;
				width: 100%;
			}
			.nav-link {
				color: #333 !important;

				@include hover-focus {
					background-color: #fafafa !important;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.nav-link {
				border-bottom: 1px solid #ECF0F1;
			}
		}
	}

	.sub-nav-toggler {
		position: absolute;
		top: 0;
		right: 0;
		border-left: 1px solid #ECF0F1;
		padding: 1rem 1.2rem;
		font-size: 0.8em;
		text-decoration: none;
		color: #333;
		vertical-align: middle;
		background-color: #FFF;

		&.collapsed {
			border-left: 1px solid #FFF;
			background-color: transparent;
			border-bottom: 0;

			i::before {
				content: "\e621";
			}
			.putnamicon-angle-up::before {
				content: "\e642";
			}
		}

		&.arrows {
			padding: 0.77rem 1.15rem;
			width: 50px;
		}

		.putnamicon-angle-up {
			font-size: 1.3rem;

			&::before {
				content: "\e641";
			}
		}
	}

	.sub-sub-nav-toggler,
	.sub-sub-nav-toggler.collapsed { 
		border-left: 1px solid #ECF0F1;
	}

	.no-main-link {

		@include media-breakpoint-down ( sm ) {
			background: #FFF;
			border-bottom: 1px solid #ECF0F1 !important;

			.putnamicon-angle-up {
				font-size: 1.3rem;

				&::before {
					content: "\e641";
				}
			}

			&.collapsed {
				background: transparent;
				border-bottom: 1px solid #FFF !important;

				.putnamicon-angle-up {

					&::before {
						content: "\e642";
					}
				}
			}
		}
	}

	&.slim-nav {
		
		.nav-item {
			
			@include media-breakpoint-up(md) {

				&.client-accounts > .sub-nav {
					right: 0;
					left: auto;
				}

				> .sub-nav {

					position: absolute;
					width: 240px;
					left: 0;

					> .nav-item {
						border-bottom: 1px solid #ccc;

						&:last-child {
							border-bottom: 0;
						}
					}
				}

				.sub-sub-nav {
					position: relative;
					box-shadow: none;
				}

				.sub-nav-toggler {
					border-left: 1px solid #CCC;
					padding: 0.9rem 1.15rem;
				}
			}
		}
	}
}

.site-individual,
.site-advisor {

	.top-nav-wrapper {

		.navbar-nav {
			
				.nav-link {
				color: #333;
				border-bottom: 1px solid #FFF;
				padding: 0.84em 1em;



				&.active {
					background-color: #FFF;
					color: #333;
					border-bottom: 1px solid #ECF0F1;
				}
			}		
		}
	}

	.top-nav-toggler {
		position: absolute;
		top: 0;
		right: 0;
		border-left: 1px solid #ECF0F1;
		padding: 1rem 1.2rem;
		font-size: 0.8em;
		text-decoration: none;
		color: #333;
		vertical-align: middle;
		background-color: #FFF;

		&.collapsed {
			border-left: 1px solid #FFF;
			background-color: transparent;
			border-bottom: 0;

			i::before {
				content: "\e621";
			}
			.putnamicon-angle-up::before {
				content: "\e642";
			}
		}

		&.arrows {
			padding: 0.77rem 1.15rem;
			width: 50px;
		}

		@include media-breakpoint-up(md) {
			&.arrows {
				max-width: 25%;
				height: 100%;
			}
		}

		.putnamicon-angle-up {
			font-size: 1.3rem;

			&::before {
				content: "\e641";
			}
		}
	}
	.topnav {
		list-style:none;
		padding: 0px;
		@include media-breakpoint-down(sm) {
			background-color: #fff;
		}
		.nav-item {
			border-bottom: 1px solid #ECF0F1;
			&.last-login {
				.nav-link {
					    font-size: .875rem;
    					color: #636c72;
				}
			}
		}
	}
}

.header-nav-drop {

	.nav-link {
		padding: 0 10px;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.2em;

		.about-link {
			&::after {
				display: none;
			}
		}

		.dropdown-menu {
			right: -10px;
			left: auto;
		}
	}
	
	.putnamicon-info {
		font-size: 1.1em;
		vertical-align: -1px;
	}

	a {
		&:visited,
		&:link {
			color: #000;
			text-decoration: none;
		}
	}

	.dropdown-item.active, .dropdown-item:active {
		background-color: #f7f7f9;
	}

	&.expanded-dropdown-label {

		.show .dropdown-toggle {
			position: relative;
			z-index: 1001;
		}
		.dropdown-menu {
			top: -15px;
			left: -15px;
			padding-top: 47px;
		}
	}
}

.site-individual {
	#top-nav-collapser {
		.tab-accordion-toggler {
			background-color: #FFF;

			&.collapsed {
				background-color: #DEE6EC;
			}
		}
	}
}

.thick-white-nav {
	background: #FFF;
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1);
	box-shadow: 0 0 2px 1px rgba(0,0,0,0.1);
	position: relative;
	padding: 20px 13px;

	&.sticky {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 30;
	}

	@include media-breakpoint-up( md ) {
		padding: 30px 13px;
	}

	@include media-breakpoint-up( lg ) {
		padding: 0;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		li {
			position: relative;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;

			&:focus-within > a,
			&:hover > a,
			&.hover > a,
			&.selected > a {
				outline: none;
				color: #2578C1;
			}

			ul {
				padding: 10px 0;
				position: absolute;
				display: none;
				background: #FFF;
				min-width: calc(100% + 40px);
				z-index: 30;
				left: -20px;
				border-bottom: 4px solid #2578C1;
				box-shadow: 0 5px 5px 1px rgba(0,0,0,0.1);
				top: 70px;

				li {

					a {
						padding: 10px 20px;
						display: block;
						width: 100%;
						font-weight: 500;
						white-space: nowrap;

						&:active {
							background-color: #F0F4F8;
						}
					}
				}
			}

			&:hover > ul,
			&.hover > ul,
			&:focus-within > ul,
			ul:hover,
			ul:focus {
				display: block;
			}

			&.selected {
				position: relative;

				&:after {
					pointer-events: none;
					position: absolute;
					content: "";
					display: block;
					background: #2379CC;
					width: calc(100% + 30px);
					bottom: 0;
					left: -15px;
					height: 4px;
				}
			}
		}
	}

	a {
		color: #333;
		text-decoration: none;
		font-weight: 600;
		font-size: 16px;

		&.btn {
			color: #FFF !important;
		}

		@include hover-focus() {
			color: #2578C1;
		}
	}

	.nav-item > a {
		padding: 40px 0;
	}
	&.sticky .nav-item > a {
		padding: 20px 0;
	}
	&.sticky ul > li > ul {
		top: 45px;
	}

	.mobile-site-logo {
		margin-right: 10px;

		img {
			width: 30px;
		}
	}

	.logo-mobile-subhead {
		text-transform: uppercase;
	}

	.main-nav-toggler,
	.corporate-nav-toggler {
		padding: 10px 15px;

	}
	.main-nav-toggler {
		span:before {
			content: "\e61e";
		}
		&.collapsed span:before {
			content: "\e960";
		}
	}
	.corporate-nav-toggler {
		span:before {
			content: "\e61e";
		}
		&.collapsed span:before {
			content: "\e961";
		}
	}
}

.thick-blue-nav {
	font-weight: 600;
    width: 100%;

	@include media-breakpoint-down( xs ) {
		overflow-x: scroll;
	}

	.nav-item {
		margin-bottom: 0 !important;
	}
	.nav-pills {
		/* padding-left: 0; */

		.nav-link.active {
			background-color: #4F88BF;
		}
	}
	a.nav-link {
		padding: 1em 0.5em;

		&:link,
		&:visited {
			color: #FFF;
		}
	}
}

.thick-vertical-list,
.thick-side-by-side-list {
	background-color: #F4F4F4;
	border-bottom: 1px solid #F4F4F4;

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		li {

			a {
				color: #000;
				text-decoration: none;
				padding: 15px 27px;
				border-bottom: 1px solid #FFF;

				&.sub-nav-toggler {
					border-left: 1px solid #FFF;
					background-color: #FFF;
					border-bottom: 1px solid #F4F4F4;
					font-size: 20px;

					&.collapsed {
						background-color: #F4F4F4;
						border-bottom: 1px solid #FFF;

						span.putnamicon-angle-up:before {
							content: "\e642";
						}
					}
				}

				&.custom-quick-collapse-link {
					background-color: transparent;
					border-bottom: 1px solid #FFF;

					&.active {
						position: relative;
						border-left: 1px solid #F4F4F4;
						color: #F4F4F4;

						&:before {
							content: "";
							position: absolute;
							top: 0;
							right: -20px;
							width: 0; 
							height: 0; 
							border-top: 30px solid transparent;
							border-bottom: 30px solid transparent;
							border-left: 30px solid #F4F4F4;
							z-index: 1;
						}
					}
				}
			}

			&.featured-blue a {
				background-color: #2A669B;
				color: #FFF;
			}

			ul {
				background-color: #FFF;

				li {
					
					a {
						border-bottom: 1px solid #F4F4F4;
					}
				}
			}
		}
	}
	.sub-links {
		background-color: #FFF;

		a {
			border-bottom: 1px solid #F4F4F4;
		}
	}
	a.featured-blue {
		background-color: #2A669B;
		color: #FFF;
		padding: 15px 27px;
		display: block;
		text-decoration: none;
	}

	.hidden {
		display: none !important;
	}
}

.nav-flex-minimal {
	background-color: #fff;
	border-bottom: 1px solid currentColor;

	&.no-border
	{
		border: none;
	}

	position: relative;
	color:$link-color;

	.sticky-bar{
		transition: opacity 0.2s;
		height: 0;
		opacity: 0;
		padding-top: 10px;
		color: $body-color;
		@include media-breakpoint-down(sm) {
			padding-bottom: 10px;
			background-color:#373A3C;
		}
		.fund-select-and-features {
			width: 100%;
		}
	}

	&.sticky-scrolled
	{
		box-shadow: 0 0 20px #999;
	}

	&>div:last-child{
		@include media-breakpoint-down(sm) {
			overflow-x: scroll;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
		}
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
	}
	
	ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		color: $link-color;

		li {
			flex-grow: 1;
			margin-bottom: 0 !important;
			
			@include media-breakpoint-down(sm) {
				flex-shrink: 0;
			}

			a {
				position: relative;
				text-transform: uppercase;
				font-weight: 400;
				display: block;
				height: 100%;
				padding:2em 0.4em 1em 0.4em;
				transition: opacity 0.2s;
				text-rendering: optimizeLegibility;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				@include media-breakpoint-down(sm) {
					white-space: nowrap;
					font-size: 14px;
				}
				
				&:link,
				&:visited {
					color: currentColor;
					text-decoration: none;
				}
				&:hover,
				&:active {
					text-decoration: none;
					opacity: 0.75;
				}

				&.active {

					&::after {
						content: "";
						display: block;
						position: absolute;
						background: currentColor;
						width: 100%;
						height: 3px;
						bottom: -4px;
						left: 0;

						@include media-breakpoint-down(sm) {
							bottom: 0;
						}
					}
				}
			}
		}
	}
}

.nav-flex-dark {
	background-color: #444752;

	#anchorTabNavCollapse
	{
		.container
		{
			@include media-breakpoint-up( lg ) {
				height: 88px !important;
				display: flex;
				align-items: center;

				ul
				{
					width: 100%;

					li
					{
						a
						{
							height: auto;
						}
					}
				}
			}
		}	
	}

	.container {
		// overflow-x: scroll;
		// overflow-y: hidden;
		// -ms-overflow-style: none;
		// -webkit-overflow-scrolling: touch;

		// &::-webkit-scrollbar {
		// 	display: none;
		// }
	}

	.mobile-toggle
	{
		color: white;
	}

	ul {
		// margin-bottom: 0;
		// list-style: none;
		// flex-wrap: nowrap;
		// padding-left: 0;

		li {

			@include media-breakpoint-down( md )
			{
				display: flex;
				align-items: center;
			}

			a {
				padding: 0.5rem 1rem;
				color: #FFF;
				text-decoration: none;

				@include media-breakpoint-up( lg ) {
					padding: 0.7rem 1.3rem;

					&.orange {
						background-color: #DB9148;	
					}
				}

				&:hover {
					background-color: #565963;
				}
				&.active {
					background-color: #565963;
				}
			}
		}
	}
}


.sticky-nav
{
	&.sticky-scrolled {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
		.sticky-bar {
			height: auto;
			opacity: 1;
		}
	}
}

.nav-flex
{
	background-color: white;
	color: $link-color;

	@include media-breakpoint-up( lg )
	{
		border-bottom: 1px solid currentColor;
	}

	a
	{
		color: $link-color;
		text-transform: uppercase;
	}

	ul
	{
		@include media-breakpoint-up( lg )
		{
			padding-top: 1.5em;
		}

		li
		{
			@include media-breakpoint-up( lg )
			{
				flex: 1 0 auto;
			}

			a
			{
				position: relative;
				display: flex;
				align-items: center;

				@include media-breakpoint-up( lg )
				{
					padding: 0 0.4em;

					&.active
					{
						&::after
						{
							content: "";
							display: block;
							position: absolute;
							background: currentColor;
							width: 100%;
							height: 3px;
							bottom: -4px;
							left: 0;
						}
					}
				}
			}
		}
	}
}

/* Fat Nav, for marketing promo items, brought over from older main.css, delete when we rebuild */
.fat-nav{
	display: none;
	position: absolute;
	z-index: 100;
	background: #efefef;
	width: 100%;
	left: 0;
	padding-top: 10px;
	padding-bottom: 12px;
	box-shadow: 0 20px 40px -2px rgba(0,0,0,0.2);
	font-size: 14px;
	line-height: 1.429em;
}
.fat-nav h4{
	border-bottom: 1px solid #ccc;
}
.fat-nav h4 a{
	color: inherit;
	text-decoration: none;
}
.fat-nav hr{
	border-color: #ccc;
	margin-bottom: 10px;
	margin-top: 10px;
}
.fat-nav ul li{
	padding-top: 3px;
	padding-bottom: 3px;
}
.fat-nav .mini-screener ul.fund-count{
	border-top:1px solid #333;
	background-color:#fff;
	margin-top:15px;
}
.fat-nav .mini-screener ul:nth-child(2){
	margin-left:15px;
}
.fat-nav .mini-screener ul.fund-count li{
	display: flex;
	justify-content: space-between;
}
.fat-nav #fund-visualizer-wrapper {
	width: 98%;
	@include media-breakpoint-up(lg){
		margin-left: 15px;
		width: 59.5%;
	}
	@include media-breakpoint-up(xl){
		width: 61.725%;
	}
}
.fat-nav .featured-manager{
	background: url('/static/img/nav/featured-manager.jpg');
	background-repeat: no-repeat;
	background-position: bottom right;
	min-height: 142px;
	border-bottom: 1px solid #ccc;
}
.fat-nav .dst-phone-help{
	font-size: 1.5em;
}
.fat-nav .dst-phone-help .putnamicon{
	font-size: 1.5em;
}
.fat-nav .mini-screener li{
	margin-bottom: 2px;
}
.fat-nav .mini-screener .fund-count li{
	border-bottom:1px solid #ddd;
	margin:0;
	padding:0.5rem 1rem;
}
.fat-nav .mini-screener .fund-count li:last-child{
	border-bottom:none;
}
.fat-nav .mini-screener .fund-count li.empty-list{
	background-color:#efefef;
	border-bottom:1px solid #333;
}
.mini-border-absolute-return{
	border-left:solid 4px #ff9900;
}
.mini-border-asset-allocation,
.mini-border-target-date{
	border-left:solid 4px #6600ff;
}
.mini-border-blend{
	border-left:solid 4px #0077f0;
}
.mini-border-global-sector{
	border-left:solid 4px #1c91b7;
}
.mini-border-growth{
	border-left: solid 4px #1ca605;
}
.mini-border-taxable-income,
.mini-border-tax-free-income,
.mini-border-fixed-income,
.mini-border-stable-value{
	border-left:solid 4px #ddba17;
}
.mini-border-value{
	border-left:solid 4px #ac0707;
}
.fa-fatnav-mp{
	padding-top: 0px;
	padding-left: 15px
}
ul li .fa-fatnav-mp{
	padding-top: 0px;
}
.fa-fatnav-no-bullet{
	margin-bottom: 0px;
}
.panagora-funds {
	border-top: 1px solid #ddd;
	margin: 0 15px !important;
	padding: 0.5rem 0 !important;
}

//529 sidebar nav style
.secondary-nav{
	margin:0 0 10px 0;
	padding:0;
	list-style:none;
	border-bottom:1px dotted #ccc;
	display:none;

	@include media-breakpoint-up(sm){
		display:block !important;   
	}

	li{
		margin:0 !important;

		a{
			display:block;
			padding:5px 12px 5px 5px;
			border-top:1px dotted #ccc;
			background:#FFF;

			@include media-breakpoint-up(sm){
				background:#F3F3F3;background:linear-gradient(to right,	#ffffff 0%,#f4f4f4 94%,#e9e9e9 100%);
			}

			&.active{
				background:#FFF;
				color:#333;
			}
		}
	}
}

.individual .secondary-nav{
	margin:0 0 10px 0;
	padding:0;
	list-style:none;
	border-bottom:1px dotted #ccc;
	display: block;
}

/* Carousel nav */
.carousel-indicators li {
	box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
}

/* Anchor Nav */
$mobile-height: 40px;
$mobile-height-large: 50px;
.anchor-tab-nav
{
	position: relative;

	a
	{
		&, &:visited, &:focus
		{
			text-decoration: none;
		}
	}

	.mobile-toggle
	{
		position: absolute;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		width: auto;
		height: $mobile-height;
		justify-content: flex-end;
		padding-left: 15px;
		z-index: 1;

		@include media-breakpoint-up( lg )
		{
			display: none;
			height: $mobile-height-large;
		}

		[aria-expanded="false"]
		{
			.putnamicon-angle-down
			{
				display: block;
			}

			.putnamicon-angle-up
			{
				display: none;
			}
		}

		[aria-expanded="true"]
		{
			.putnamicon-angle-down
			{
				display: none;
			}

			.putnamicon-angle-up
			{
				display: block;
			}
		}
	}

	#anchorTabNavCollapse
	{
		min-height: $mobile-height;
		&.collapse
		{
			&:not( .show )
			{
				display: block;
				height: $mobile-height;
				overflow-y: hidden;
				pointer-events: none;

				@include media-breakpoint-up( lg )
				{
					height: auto;
					overflow-y: visible;
					pointer-events: initial;
				}

				ul
				{
					li
					{
						a:not( .active )
						{
							display: none !important;
							@include media-breakpoint-up( lg )
							{
								display: flex !important;
							}
						}
					}
				}

			}
		}

		&.collapsing
		{
			height: $mobile-height;
		}
	}

	ul
	{
		margin: 0;
		padding: 0;

		@include media-breakpoint-up( lg )
		{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		li
		{
			list-style: none;
			margin-bottom: 0 !important;

			@include media-breakpoint-down( md )
			{
				display: flex;
				justify-content: flex-end;
			}

			a
			{
				height: $mobile-height;

				@include media-breakpoint-up( lg )
				{
					height: $mobile-height-large;	
				}
			}
		}
	}
}


//
// Colored tab theme
//

.nav-tabs-colored {
	--selected-bg: #{$gray-700};
	.nav-item {
		margin-bottom: 0 !important;
	}
	.nav-link {
		color: $primary;
		background-color: $gray-300;
	}
	.nav-link.active,
	.nav-item.show .nav-link {
		color: $white;
		background-color:var(--selected-bg);
	}
}

//
// Asset class tab theme
//


.new-auth-dropdown {
	.dropdown-menu {
		box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
		margin-top: 0.5rem;
	}

	.dropdown-toggle::after {
		vertical-align: 3px;
	}

	.dropdown-item {
		padding: .4rem 1rem;
	}
	.last-login {
		padding: .6rem 1rem;
	}

	.dropdown-divider {
		width: calc(100% - 25px);
		margin: 0 auto;
	}
}

// Utility
.sticky-scrolled
{
	box-shadow: 0 0 20px #999;
}

.auto-chart {
	width: 100%;
}

/* Bubble chart */
.auto-chart[data-custom='high-low'] .highcharts-container,
.auto-chart[data-custom='high-low'] .highcharts-root {
	overflow: visible !important;
}

.auto-chart-arrow{
	width:75px;
	padding: 15px;
	text-align: center;
}
.auto-chart-arrow::before{
	content: "";
	position: absolute;
	width:0;
	height: 0;
	border: 6px solid transparent;
}

.auto-chart-arrow-up,
.auto-chart-arrow-down,
.auto-chart[data-custom='high-low'] .x-point{
	color: #78B659;
}
.auto-chart-arrow-up::before{
	bottom: 0;
	left: 50%;
	margin-left: -6px;
	border-bottom: 12px solid #78B659;
}
.auto-chart-arrow-down::before{
	top: 0;
	left: 50%;
	margin-left: -6px;
	border-top: 12px solid #78B659;
}


.auto-chart-arrow-left,
.auto-chart-arrow-right,
.auto-chart[data-custom='high-low'] .y-point{
	color: #337ABD;
}
.auto-chart-arrow-left::before{
	right: 0;
	top: 17px;
	border-right: 12px solid #337ABD;
}
.auto-chart-arrow-right::before{
	left: 0;
	top: 17px;
	border-left: 12px solid #337ABD;
}

.auto-chart[data-custom='high-low'] .x-point,
.auto-chart[data-custom='high-low'] .y-point {
	min-width:25px;
	text-align:center;
}

.key-box {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 9px;
    margin-bottom: 1px;
}
.brand-wrapper {

	a.site-logo {
		width: 105px;
		margin: 0;

		@include media-breakpoint-up(md) {
			width: 150px;
		}

		@include media-breakpoint-up(lg) {
			width: 160px;
		}

		@include media-breakpoint-up(xl) {
			width: auto;
		}

		img{
			height: 26px;
			vertical-align: -2px;

			@include media-breakpoint-up(sm){
				width: 139px;
				height: 34px;
				vertical-align: -10px;
			}

			@include media-breakpoint-up(md){
				width: 192px;
				height: 71px;
				vertical-align: -10px;
			}			
		}
	}
}


.logo-row {
	padding-top: 1em;
	padding-bottom: 1em;

	@include media-breakpoint-up(md) {
		padding-top: 2em;
    	padding-bottom: 2em;
	}
}

#header-links {
	position: absolute;
	top: 1.2rem;
	right: 3px;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			float: left;

			> a,
			> span {
				padding: 0.7rem;
				color: #333;
				text-decoration: none;

				.putnamicon-plus {
					font-size: 0.6rem;
					vertical-align: 1px;
				}
			}
		}
	}
}

#cse-search-box-menu, #fv-search-form {
	display: none;
	clear: right;
	margin-top: 10px;

	@include media-breakpoint-up(md){
		display: block;
	}
}

.search{
	display: block;
	float: right;
	max-width: 191px;
	height: 26px;
	padding-left: 10px;
	margin: 10px 20px 15px 0;
	background-color: #ffffff;
	box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
	border-radius: 15px;
	background-clip: padding-box;

	&-submit {
		width: 30px;
		height: 26px;
		display: block;
		float: right;
		margin: 0;
		padding: 0;
		border: 0;
		background: url(/static/img/icons/icon-search.png) no-repeat center 8px;
	}
	&-input {
		float: left;
		outline: none;
		border: 0 none;
		background: transparent;

		@include media-breakpoint-up(sm){
			font-size:13px;
		}
	}
}

.twitter-typeahead {
	width: 147px;
	float: left;
}

.typeahead, .tt-query, .tt-hint {
	width: 147px;
	height: 26px;
	padding: 1px 4px 0px 4px;
	margin: 0px;
	line-height: 26px;
}

a.logo-subhead {
	display: inline-block;
	padding: 0 0 0 10px;
	border-left: 1px solid #CCC;
	margin-left: 10px;
	font-size: 1.3em;
	font-weight: 300;

	@include media-breakpoint-up(sm) {
		font-size: 1.7em;
		padding: 2px 0 2px 20px;
		margin-left: 20px;
	}

	&:link,
	&:visited {
		color: #333;
		text-decoration: none;
	}
}


/* Social Sharing and breadcrumbs */

@media (min-width:768px) and (max-width: 991px){
	.select-prod-info-fund{
		max-width:260px;
	}
}

#location-share{
	
	@include media-breakpoint-up(md){
		margin: 0 0 10px;
        padding: 10px 0 10px 0;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);	
	}

	#social-icons {
		height: 27px;

		@include media-breakpoint-down(sm){
			display: none;
		}
		@include media-breakpoint-up(md){
			font-weight: 600;
    	    position: relative;
		}
		
		a {
			color: #333;
			text-decoration: none;
			margin-left: 0.2em;

			@include media-breakpoint-up(md){
				margin-left: 5px;
			}

			&:first-child {
				margin-left: 0;
			}

			.putnamicon {
				font-size: 1.4em;
			}

			.putnamicon-facebook-sign {
				color: #00528b;
			}
			.putnamicon-twitter-sign {
				color: #00b6e8;
			}
			.putnamicon-linkedin-sign {
				color: #0098b6;
			}
			.putnamicon-google-plus-sign {
				color: #c82d34;
			}
		}
	}
	
	.share-social {
		vertical-align: 3px;
	}

	#breadcrumb {
		display: none;
		position: relative;
	    line-height: 30px;

		@include media-breakpoint-down(sm) {
			padding-top:5px;
			float: left;
			white-space: nowrap;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			width: calc(100% - 130px);

			&::-webkit-scrollbar { 
				display: none; 
			}

			.fade {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 100%);
				position: absolute;
			}
		}

		@include media-breakpoint-up(md){
	    	display: block;
	    }

		a {
			margin-right: 5px;
			margin-left: 0.2em;
			padding-right: 14px;
			position: relative;
			display: inline-block;
			float: left;

			&:first-child {
				margin-left: 0;
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}

			@include media-breakpoint-up(md){
				a {
			        margin-right: 5px;
			        padding-right: 14px;
			        background: url('/static/img/raquo.png') no-repeat right 13px;
			        float: left;
			    }
			}

			&:hover {
				text-decoration: underline;
			}

			&::after {
				content: "\e663";
				font-family: "icomoon";
				position: absolute;
				right: 0;
				top: 1px;
				color: #999;
				font-size: 0.7em;
			}
		}

		.checkbox input {
		    margin-top: 9px;
		}

		span {
			display: inline-block;
			float: left;
			margin-left: 0.2em;

			@include media-breakpoint-down(sm) {
				float: none;
			}
		}

		.breadcrumb-ellipsis {

			@include media-breakpoint-up(md) {
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 160px;
				white-space: nowrap;
			}
			@include media-breakpoint-up(lg) {
				max-width: 310px;
			}
			@include media-breakpoint-up(xl) {
				max-width: 620px;
			}
		}
	}

	#literature {
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
	}
}

/* FT info banner */
#ft-info-message {
	background-color: #FFF;
	padding-bottom: 1rem;
}

// Disclosure and Content hiding
.disclosure-hide, .content-hide{
	position:relative;
	overflow:hidden;
	z-index:1;
	padding-bottom:4em;
	margin-bottom:15px;
	transition:max-height 0.75s ease-out;

	// Overlay
	&::before {
		content:'See important disclosure';
		position:absolute;
		z-index:3;
		bottom:22px;
		left:50%;
		margin-left:-90px;
		padding:3px 2px 2px 2px;
		width:180px;
		height:28px;
		text-align:center;
		font-size:rem(11);
		color:#007CC6;
		background-color:#fafafa;
		border: solid 1px #BABABA;
		zoom:1;
	}

	// Dropshadow
	&::after {
		content:'';
		position:absolute;
		z-index:2;
		bottom:0;
		left:0;
		width:100%;
		height:50px;
		border-top:solid 1px #BABABA;
		background: $white;
	}

	&.show::before {
		content:'Hide disclosure';
	}
	&.show::after {
		box-shadow: none;
	}

	p {
		margin-bottom: 1.666em;
	}

	@media print {
		max-height:10000px !important;
		height:100%;
		width:100%;
		margin:0;
	}
	&::before, &::after {
		@media print {
			content:none;
			clear:both;
		}
	}
}

.disclosure-hide {
	&[data-tab-open]::before {
		content:attr(data-tab-open);
	}
	&[data-tab-open].show::before {
		content:attr(data-tab-close);
	}
	&[data-fully-close="true"]::after {
		box-shadow:none;-webkit-box-shadow:none;-moz-box-shadow:none;
	}
}

.content-hide {
	&::before {
		content:'Read more';
	}
	&.show::before {
		content:'Show less';
	}
}

.hide-toggle{
	position: absolute;
	bottom: 10px;
	width: 100%;
	height: 50px;
	cursor:pointer;
	z-index: 4;
}
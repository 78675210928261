@font-face {
	font-family: 'icomoon';
	src:
		url('/static/fonts/icomoon.woff2?v=6') format('woff2'),
		url('/static/fonts/icomoon.ttf?v=6') format('truetype'),
		url('/static/fonts/icomoon.woff?v=6') format('woff'),
		url('/static/fonts/icomoon.svg?v=6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	display: inline-block;
	width: 14px;
	height: 14px;
	margin-top: 1px;
	line-height: 14px;
	vertical-align: text-top;
	background-image: url(/static/img/individual/layout/sh_icon_sprite_g.png);
	background-repeat: no-repeat;
}

[class^="icon-social"], [class*="icon-social"] {
	width: 22px;
	height: 22px;
	line-height: 22px;
	overflow: hidden;
	text-indent: 100%;
	background-image: url(/static/img/individual/layout/icons-social-2x.png);
	background-repeat: no-repeat;
	background-size: 150px 290px;
	&:hover {
		opacity: 0.7;
	}
}

.animated {
  transition: opacity 0.2s ease-in-out;
}

@import "icon-selectors";
@mixin configure_icons()
{
	@each $id, $content, $isActive, $color in $icon-selectors {

		@if $isActive == true {
			&-#{$id}:before
			{
		content: unquote("\"\\#{$content}\"");

		@if $color != null {
					color: $color;
				}
			}
		}
	}
}

.icon- {
	&social-email {
		background-position: -44px -139px;
	}

	&social-facebook {
		background-position: -44px 0;
	}

	&social-twitter {
		background-position: -44px -29px;
	}

	&social-google {
		background-position: -44px -113px;
	}

	&social-linkedin {
		background-position: -44px -86px;
	}
	&success:before{
		color: #265c20;
	}
	&warn:before{
		color: #f4a403;
	}
	&error:before{
		color: #95142b;    
	}
}


@include media-breakpoint-down(md){
	#social-icons a {
		margin-left: 5px;
	}
}

.putnamicon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include configure_icons();
}

.helpful-input-button {
	position: absolute;
	top: 2.5em;
	right: 0;
	padding: 5px;
	text-align: center;
	cursor: help;
	text-decoration: none !important;
	color: #006ead !important;
	z-index: 1;

	&:focus {
		outline: none;
		box-shadow: none;
	}

	.putnamicon {
		font-size: 1.4rem;
		vertical-align: -8px;
	}
}
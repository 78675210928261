.text-heavy{
	font-weight: 600;
}
.chart-underline-none a{
	text-decoration: none;
}
.blue-quote{
	color: #0079c5;
}
.green-text-impact{
	color: #40a640;
}
.blackstar {
	color: black;
}
.player-ico{
	padding-right: 5px;
}
.pt-mini{
	padding-top: 10px;
}
.purple-txt-padding-number{
	margin-right: 8px;
}
.blue-text-approach{
	color: #1d95a3;
}
/* Source Sans Pro regular fonts */
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: normal;
	font-weight: 300;
	src: local('Source Sans Pro Light'),
		 local('SourceSansPro-Light'),
		 url('/static/fonts/source-sans-pro-300.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-300.woff') format('woff');
}
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: normal;
	font-weight: 400;
	src: local('Source Sans Pro Regular'),
		 local('SourceSansPro-Regular'),
		 url('/static/fonts/source-sans-pro-400.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-400.woff') format('woff');
}
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro SemiBold'),
		 local('SourceSansPro-SemiBold'),
		 url('/static/fonts/source-sans-pro-600.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-600.woff') format('woff');
}
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'),
		 local('SourceSansPro-Bold'),
		 url('/static/fonts/source-sans-pro-700.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-700.woff') format('woff');
}

/* Source Sans Pro italic fonts */
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: italic;
	font-weight: 300;
	src: local('Source Sans Pro Light Italic'),
		 local('SourceSansPro-LightItalic'),
		 url('/static/fonts/source-sans-pro-300-italic.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-300-italic.woff') format('woff');
}
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: italic;
	font-weight: 400;
	src: local('Source Sans Pro Italic'),
		 local('SourceSansPro-Italic'),
		 url('/static/fonts/source-sans-pro-400-italic.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-400-italic.woff') format('woff');
}
@font-face {
	font-family: 'Source Sans Pro';
	font-display: fallback;
	font-style: italic;
	font-weight: 600;
	src: local('Source Sans Pro SemiBold Italic'),
		 local('SourceSansPro-SemiBoldItalic'),
		 url('/static/fonts/source-sans-pro-600-italic.woff2') format('woff2'),
		 url('/static/fonts/source-sans-pro-600-italic.woff') format('woff');
}

/* Source Sans Pro variable font */
@font-face {
	font-family: 'Source Sans Variable';
	font-display: fallback;
	font-style: normal;
	font-weight: 1 999;
	src: url('/static/fonts/source-sans-variable.woff2') format('woff2' supports variations);
	src: url('/static/fonts/source-sans-variable.woff2') format('woff2');
}

/* Source Sans Pro variable italic font */
@font-face {
	font-family: 'Source Sans Variable';
	font-display: fallback;
	font-style: italic;
	font-weight: 1 999;
	src: url('/static/fonts/source-sans-variable-italic.woff2') format('woff2' supports variations);
	src: url('/static/fonts/source-sans-variable-italic.woff2') format('woff2');
}
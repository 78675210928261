.author-title-image
{
  
  .author-image
  {
    width: 70px;
    height: 70px;
  }

  h1
  {
    flex: 1;
  }

  #author-info
  {
    flex: 1;
    flex-basis: 100%;
  }
}

.article-content
{

  ul
  {
    padding-left: 1rem;
  }
}

.alignright
{
  float: right;
}

/* NEW BS4 Blog Posts AND Collated Blog Posts */

// .card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     background-color: #fff;
//     border: 1px solid rgba(0,0,0,0.125);
//     border-radius: 0;
// }

.card-block {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.filter-box {

}

.filter-box .card {
    background-color: #f5f8fc;
}

.post-tag {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.post-title {

}

.post-title a {
    color: #333;
    transition: color 0.15s;
}

.post-excerpt {

}

.post-link {

}

@media (min-width: 768px){
  .white-background {
    background-color: rgba(255,255,255,0.85);
    position: absolute;
    top: 20px;
    padding: 0 20px;
    width: calc(100% - 170px);
  }
}

@media (min-width: 1200px){
  .white-background {
    top: 50px;
    width: calc(100% - 250px);
  }
}

/* NEW BS4 Minimized Blog Posts */

.post-date {

}

.blog-title {

}

.no-posts {
  
  
}

.rate-container {
  display: flex;
  align-items: center;

  .rate-label
  {
    margin-right: 5px;
  }

  .rate-tip
  {
    margin-left: 5px;
  }
}

// This is a fix for the whitepaper featured box on IE
#featured-box
{
  >.row
  {
    width : 100%;
  }
}

// New CTAs July 2023
.cta-block-link {
	text-decoration: none;
	display: block;
	color: #000;
	height: 100%;

	a,
	a:hover,
	a:focus,
	a:active {
		color: #000;
	}
	.cta-block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 30px;
		transition: background-color 0.2s ease-in-out;
	}
	.cta-block.cta-block-green {
		background-color: #afc300;
	}
	.cta-block.cta-block-blue {
		background-color: #00b5f9;
	}
	&:hover .cta-block.cta-block-green {
		background-color: #c5d300;
	}
	&:hover .cta-block.cta-block-blue {
		background-color: #00c7ff;
	}
}
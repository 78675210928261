footer.basic-footer {
	border-top: 4px double #cad1d5;
	color: #666;
	padding-bottom: 25px;

	a {
		&:link,
		&:visited {
			color: #555;
		}
	}
}
.footer-links {
	
	@include media-breakpoint-down(md) {
		div:last-child h6 {
			border-bottom: 1px solid #CCC;
		}
	}

	h6 {
		font-weight: 600;
		margin-bottom: 0;
		margin-top: 0;

		@include media-breakpoint-down(md) {
			cursor: pointer;
			padding: 1em 0.5em;
			border-top: 1px solid #CCC;
			border-bottom: 1px solid transparent;

			&.active {
				border-bottom: 1px solid #CCC;
			}
		}
	}
	ul {
		list-style: none;
		padding-left: 0;

		@include media-breakpoint-down(md) {
			margin-bottom: 0;

			li {

				a {
					display: block;
					padding: 1em 0.5em;
					border-top: 1px solid #CCC;
					background-color: #EFEFEF;

					&:hover {
						background-color: #F5F5F5;
					}
				}
				&:first-child a {
					border-top: 0;
				}
			}
		}
		
		@include media-breakpoint-up( lg ) {
			li {
				margin-top: 0.5em;

				&.has-subcaption {
					line-height: 1.1;
					margin-top: 0.7em;
					padding-bottom: 3px;
				}
			}
		}
	}
	a.social {
		font-size: 1.5rem;
		text-decoration: none;
		flex: 1;

		&.social-white {
			padding: 0.8rem 0;
		}

		@include media-breakpoint-up( lg ) {

			&.social-white {
				font-size: 1.3rem;
				padding: 0;
				width: 38px;
				height: 38px;
				line-height: 38px;
				border-radius: 5px;
				background-color: #FFF;
				border: 1px solid #CCC;
				text-align: center;
				margin-right: 3px;

				.putnamicon-twitter {
					line-height: 35px;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&:hover {
			color: #888;
		}
	}
}
#footer-disclosure{
	a {
		color: #064bad;

		&:hover {
			color: #555;
		}

		&[href^=tel] {
			color: #555;
		}
	}
}
.chart-label-section
{
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-right: 0;
}

.chart-label
{
  position: relative;
  margin: 6px 0;
}

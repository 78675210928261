aside{
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #d6d6d6;
    margin-bottom: 1em;
  }
  @include media-breakpoint-down(md) {
    border-left: 0px;
  }
  .dotted{
    .dot-rule:first-child{
    	border-top:none !important;
    }
  }
  
  .module{
    border-top: 1px solid #d6d6d6;
    padding-top: 10px;
    padding-bottom: 10px;
    &:first-child {
      border-top: 0;
    }
  }
  &.no-dot-rule{
    border-left:0px;
  }
}

aside.dotted {
    border-left: 1px solid #d6d6d6;
    border-top: none;
    margin-bottom: 1em;
    padding: 0px 0 10px 10px;
}
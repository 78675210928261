.collapse-tabs {

	.tab-accordion-toggler,
	.tab-accordion-link {
		display: flex;
		width: 100%;
		padding: 12px 15px;
		color: #444;
		border-bottom: 1px solid #FFF;
		text-decoration: none;
		text-transform: uppercase;

		span {
			margin-left: auto;
			font-size: 1.3rem;
			line-height: 1.1;
		}

		&.collapsed {

			span::before {
				content: "\e642";
			}
		}
	}
}

/* Arrow theme */
.nav-tabs-arrow {
	position: relative;
	z-index: 1;
	.nav-item {
		margin:0 !important;
	}
	.nav-link {		
		position: relative;
		margin-right: 10px;
		padding:0 20px;
		line-height: 32px;
		background-color: #fff;
		border: none;
		border-radius: 0;
		cursor: pointer;
		color: #666;
		-webkit-transition: all 0.2s;
		transition: all 0.2s;
		font-size: 14px;
		&:before {
			content: '';
			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;
			z-index: -2;
			border: solid 1px #ddd;
			-webkit-transition: border 0.2s;
			transition: border 0.2s;
		}
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 15px;
			height: 15px;
			bottom: 5px;
			right: 50%;
			margin-right: -7px;
			z-index: -1;
			border: solid 1px #ddd;
			background-color: #fff;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			-webkit-transition: all 0.2s;
			transition: all 0.2s;
		}
		&:hover {
			background-color: #f8f8f8;
			&:before {
				border-color: #bbb;
			}
			&:after {
				bottom:-7px;
				background-color: #fafafa;
				border-color: #bbb;
			}
		}
		&.active {
			color: #efefef !important;
			background-color: #555 !important;
			&:after {
				bottom:-7px;
				background-color: #555;
			}
		}
	}
	&.tight {
		a {
			padding:0 5px;
			margin: 0 2px 0 0;
			font-size: 13px;
			@include media-breakpoint-up(md) {
				padding:0 4px;
				margin: 0 1px 0 0;
			}
			@include media-breakpoint-up(lg) {
				padding:0 8px;
				margin: 0 4px 0 0;
				font-size: 14px;
			}
			@include media-breakpoint-up(xl) {
				padding:0 18px;
				margin: 0 8px 0 0;
			}
		}
	}
	.tab-drop-wrap {
		padding-bottom: 10px !important;
		@include media-breakpoint-up(sm) {
			display:none;
			select {
				width:90%;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.perspectives .nav-tabs-arrow li {
		padding:0 4px;
	}
}
@include media-breakpoint-up(lg) {
	.perspectives .nav-tabs-arrow li {
		padding:0 8px;
	}
}
@include media-breakpoint-up(xl) {
	.perspectives .nav-tabs-arrow li {
		padding:0 20px;
	}
}

@include media-breakpoint-down(sm) {
	#combined-login .tab-drop-wrap,
	.tabbed-area ul.tabbed-nav li{
		display: none;
	}
	#combined-login.tabbed-area ul.tabbed-nav li {
        display: inline-block;
    }
}


/* Old tab styles, delete these when we migrate tabs to something better */

/* ==========================================================================
	Tabbed Navigation
	 ========================================================================== */

.tabbed-content{
	clear:both;
}

/* Minimal theme */
.tabs-minimal{
	list-style-type:none;
	//padding-bottom:7px;
	padding-left: 0;
	margin-bottom: 0;
	border-bottom: solid 1px #dddddd;
}
.tabs-minimal li{
	position: relative;
	display:inline;
	bottom: -3px;
	padding:0 5px 3px 0px;
	cursor:pointer;
	font-size: 12px;
	margin:0 15px;
}
.tabs-minimal li.active{
	bottom: -2px;
	cursor:text;
	font-weight:600;
	border-bottom:5px solid #bbc8dc;
}
@media (min-width: 768px){
	.tabs-blue{
		padding:0 0 0 20px;
	}
	.tabs-blue li{
		display:list-item;
	}
	.tab-drop-wrap select{
		width:90%;
		width:calc(100% - 55px) !important;
	}
	.tab-drop-wrap{
		display:none;
	}
}


@media (min-width:992px){
	.tabs-minimal li{
		font-size: 14px;
	}
}
@media (min-width:1200px){
	.tabs-minimal li{
		font-size: 15px;
	}
	.tabs-minimal li.active{
		bottom:0;
	}
}

/* Colored theme */
.tabs-colored{
	list-style-type:none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	li {
		flex: 1 auto;
		padding: 1rem 2rem;
		cursor:pointer;
		font-size: rem(14);
		color: $primary;
		background-color:$white;
		&.active{
			cursor:text;
			font-weight:600;
			color:$black;
			background-color:#f5f5f5;
		}
		@include media-breakpoint-up( md ){
			font-size: rem(16);
		}
	}
}
/* Arrow theme */
.tabs-arrow{
	position: relative;
	z-index: 1;
	padding: 0;
	margin-bottom:0;
	border-bottom: solid 1px #ddd;
}
.tabs-arrow li{
	position: relative;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 0 !important;
	padding:0 20px;
	line-height: 32px;
	background-color: #fff;
	cursor: pointer;
	color: #666;
	transition: all 0.2s;
	font-size: 14px;
}
.tabs-arrow.tight li{
	padding:0 5px;
	margin: 0 2px 0 0;
	font-size: 13px;
}
.tabs-arrow li:hover{
	background-color: #f8f8f8;
}
.tabs-arrow li:before{
	content: '';
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	z-index: -2;
	border: solid 1px #ddd;
	transition: border 0.2s;
}
.tabs-arrow li:hover:before{
	border-color: #bbb;
}
.tabs-arrow li.active{
	color: #efefef;
	background-color: #555;
}
.tabs-arrow li:after{
	content: '';
	position: absolute;
	display: block;
	width: 15px;
	height: 15px;
	bottom: 5px;
	right: 50%;
	margin-right: -7px;
	z-index: -1;
	border: solid 1px #ddd;
	background-color: #fff;
	transform: rotate(45deg);
	transition: all 0.2s;
}
.tabs-arrow li:hover:after{
	bottom:-7px;
	background-color: #fafafa;
	border-color: #bbb;
}
.tabs-arrow li.active:after{
	bottom:-7px;
	background-color: #555;
}
.tabs-arrow .tab-drop-wrap{
	padding-bottom: 10px !important;
}
@media (min-width: 768px){
	.tabs-arrow.tight li{
		padding:0 4px;
		margin: 0 1px 0 0;
	}

	.perspectives .tabs-arrow li{
		padding:0 4px;
	}
}
@media (min-width: 992px){
	.tabs-arrow.tight li{
		padding:0 8px;
		margin: 0 4px 0 0;
		font-size: 14px;
	}

	.perspectives .tabs-arrow li{
		padding:0 8px;
	}
}
@media (min-width: 1200px){
	.tabs-arrow.tight li{
		padding:0 18px;
		margin: 0 8px 0 0;
	}

	.perspectives .tabs-arrow li{
		padding:0 20px;
	}
}

@media (max-width: 767px){
	#combined-login .tab-drop-wrap, .tabbed-area ul.tabbed-nav li{
		display: none;
	}
	#combined-login.tabbed-area ul.tabbed-nav li{
		display: inline-block;
	}
}

// Needed for unified login

@media (min-width: 768px) {
	.tabs-blue {
		padding: 0 0 0 20px;
	}

	.tabs-blue li {
		display: list-item;
	}

	.tab-drop-wrap select {
		width: 90%;
		width: calc(100% - 55px) !important;
	}

	.tab-drop-wrap {
		display: none;
	}
}
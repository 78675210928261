.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
	display: none !important
}
@include media-breakpoint-down(sm) {
	.visible-xs {
		display: block !important
	}
	table.visible-xs {
		display: table !important;
	}
	tr.visible-xs {
		display: table-row !important
	}
	th.visible-xs,
	td.visible-xs {
		display: table-cell !important
	}
}
@include media-breakpoint-only(sm) {
	.visible-sm {
		display: block !important
	}
	table.visible-sm {
		display: table !important;
	}
	tr.visible-sm {
		display: table-row !important
	}
	th.visible-sm,
	td.visible-sm {
		display: table-cell !important
	}
}
@include media-breakpoint-only(md) {
	.visible-md {
		display: block !important
	}
	table.visible-md {
		display: table !important;
	}
	tr.visible-md {
		display: table-row !important
	}
	th.visible-md,
	td.visible-md {
		display: table-cell !important
	}
}
@include media-breakpoint-only(lg) {
	.visible-lg {
		display: block !important
	}
	table.visible-lg {
		display: table !important;
	}
	tr.visible-lg {
		display: table-row !important
	}
	th.visible-lg,
	td.visible-lg {
		display: table-cell !important
	}
}
@include media-breakpoint-up(xl) {
	.visible-xl {
		display: block !important
	}
	table.visible-xl {
		display: table !important;
	}
	tr.visible-xl {
		display: table-row !important
	}
	th.visible-xl,
	td.visible-xl {
		display: table-cell !important
	}
}
@include media-breakpoint-down(sm) {
	.hidden-xs {
		display: none !important
	}
}
@include media-breakpoint-only(sm) {
	.hidden-sm {
		display: none !important
	}
}
@include media-breakpoint-only(md) {
	.hidden-md {
		display: none !important
	}
}
@include media-breakpoint-only(lg) {
	.hidden-lg {
		display: none !important
	}
}
@include media-breakpoint-up(xl) {
	.hidden-xl {
		display: none !important
	}
}
.visible-print {
	display: none !important
}
@media print {
	.visible-print {
		display: block !important
	}
	table.visible-print {
		display: table !important;
	}
	tr.visible-print {
		display: table-row !important
	}
	th.visible-print,
	td.visible-print {
		display: table-cell !important
	}
}
/* From Bootstrap 4.2, delete when we get there */
@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
/* END deletable area */

@media print {

	* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
		color-adjust: exact !important;                 /*Firefox*/
	}
	
	@page {
		size: 1400px 1925px;
	}

	/* Make the body white */
	body {
		background-color: #FFF;
	}

	/* This is a print bug fix that happens in Safari with Bootstrap, might be fixed in newer versions of BS, so might be deletable in the future */
	.container {
		width: auto;
	}

	p {
		page-break-inside: avoid;
	}

	/* For blog content, hide the unneeded sections */
	.article .basic-header,
	.nav-dark-wrapper,
	.basic-footer,
	.article-sidebar,
	.brightcove-video-link,
	#header-links,
	#location-share,
	#subscription-tab,
	#back-to-top {
		display: none !important;
	}

	.logo-row {
		padding-top:0;
	}

	.print-header {
		font-size: 0.7em;
		padding: 0.6em 2em;
		text-transform: uppercase;
		border: 1px solid #CCC;

		strong {
			font-weight: 600;
		}
	}

	/* Print disclosure can be smaller than body copy */
	.print-disclosure {
		font-size: 0.8em;
		line-height: 1.66;
		padding-top: 2em;
	}
	
	.disclosure-hide,
	.content-hide {
		max-height: none !important;
		padding-bottom: 0;
		margin-bottom: 0;

		&::before,
		&::after {
			content: none !important;
		}
	}

	/* Create a new grid system for print.  Bootstrap shows the smallest breakpoint (XS) as default, regardless of how big you make the page layout for print.  This allows us to float elements for print that would look bad for tiny mobile devices.  This is NOT in newer versions of Bootstrap so might require this for a while */
	.col-print-1,
	.col-print-2,
	.col-print-3,
	.col-print-4,
	.col-print-5,
	.col-print-6,
	.col-print-7,
	.col-print-8,
	.col-print-9,
	.col-print-10,
	.col-print-11,
	.col-print-12,
	.col-print {
		position:relative;
		width:100%;
		min-height:1px;
		padding-right:15px;
		padding-left:15px
	}
	.col-print {
		flex-basis:0;
		flex-grow:1;
		max-width:100%
	}
	.col-print-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-print-1 {
		flex:0 0 8.33333%;
		max-width:8.33333%
	}
	.col-print-2 {
		flex:0 0 16.66667%;
		max-width:16.66667%
	}
	.col-print-3 {
		flex:0 0 25%;
		max-width:25%
	}
	.col-print-4 {
		flex:0 0 33.33333%;
		max-width:33.33333%
	}
	.col-print-5 {
		flex:0 0 41.66667%;
		max-width:41.66667%
	}
	.col-print-6 {
		flex:0 0 50%;
		max-width:50%
	}
	.col-print-7 {
		flex:0 0 58.33333%;
		max-width:58.33333%
	}
	.col-print-8 {
		flex:0 0 66.66667%;
		max-width:66.66667%
	}
	.col-print-9 {
		flex:0 0 75%;
		max-width:75%
	}
	.col-print-10 {
		flex:0 0 83.33333%;
		max-width:83.33333%
	}
	.col-print-11 {
		flex:0 0 91.66667%;
		max-width:91.66667%
	}
	.col-print-12 {
		flex:0 0 100%;
		max-width:100%
	}
	
	/* Also all the Bootstrap col helpers, but for print */
	.order-print-1 {
		order:1
	}
	.order-print-2 {
		order:2
	}
	.order-print-3 {
		order:3
	}
	.order-print-4 {
		order:4
	}
	.order-print-5 {
		order:5
	}
	.order-print-6 {
		order:6
	}
	.order-print-7 {
		order:7
	}
	.order-print-8 {
		order:8
	}
	.order-print-9 {
		order:9
	}
	.order-print-10 {
		order:10
	}
	.order-print-11 {
		order:11
	}
	.order-print-12 {
		order:12
	}
	.pull-print-0 {
		right:auto
	}
	.pull-print-1 {
		right:8.33333%
	}
	.pull-print-2 {
		right:16.66667%
	}
	.pull-print-3 {
		right:25%
	}
	.pull-print-4 {
		right:33.33333%
	}
	.pull-print-5 {
		right:41.66667%
	}
	.pull-print-6 {
		right:50%
	}
	.pull-print-7 {
		right:58.33333%
	}
	.pull-print-8 {
		right:66.66667%
	}
	.pull-print-9 {
		right:75%
	}
	.pull-print-10 {
		right:83.33333%
	}
	.pull-print-11 {
		right:91.66667%
	}
	.pull-print-12 {
		right:100%
	}
	.push-print-0 {
		left:auto
	}
	.push-print-1 {
		left:8.33333%
	}
	.push-print-2 {
		left:16.66667%
	}
	.push-print-3 {
		left:25%
	}
	.push-print-4 {
		left:33.33333%
	}
	.push-print-5 {
		left:41.66667%
	}
	.push-print-6 {
		left:50%
	}
	.push-print-7 {
		left:58.33333%
	}
	.push-print-8 {
		left:66.66667%
	}
	.push-print-9 {
		left:75%
	}
	.push-print-10 {
		left:83.33333%
	}
	.push-print-11 {
		left:91.66667%
	}
	.push-print-12 {
		left:100%
	}
	.offset-print-1 {
		margin-left:8.33333%
	}
	.offset-print-2 {
		margin-left:16.66667%
	}
	.offset-print-3 {
		margin-left:25%
	}
	.offset-print-4 {
		margin-left:33.33333%
	}
	.offset-print-5 {
		margin-left:41.66667%
	}
	.offset-print-6 {
		margin-left:50%
	}
	.offset-print-7 {
		margin-left:58.33333%
	}
	.offset-print-8 {
		margin-left:66.66667%
	}
	.offset-print-9 {
		margin-left:75%
	}
	.offset-print-10 {
		margin-left:83.33333%
	}
	.offset-print-11 {
		margin-left:91.66667%
	}
}
// Fixes Safari bug that crashes with variable fonts
select,
select.form-control {
	font-family: sans-serif !important;
}

// This fixes the color, which is grey without it
select.form-control {
	color: #000;
}
select.form-control:focus {
	color: #000;
}

.form-fancy .form-group {
	position: relative;
	margin-bottom: 1.2rem;
}
.form-fancy label {
	position: absolute;
	top: 4px;
	padding:0;
	font-size: 18px;
	font-weight: 600;
	color:#555;
	transition: all 0.1s ease-out;
}
.form-fancy .form-control {
	padding-left: 0;
	font-size: 18px;
	font-weight: 600;
	border: 0 none;
	border-bottom: solid 3px #ccc;
}
.form-fancy .form-control.error,
.form-fancy .form-control.error-text{
	border-color: #ff0000;
}
.form-fancy .form-control.error + label,
.form-fancy .form-control.error-text + label{
	color: #ff0000;
}
.error-text {
	color : #95142b;
}
input.error {
	color : #333 !important;
}
input::-ms-clear {
    display: none;
}
.form-fancy .form-control:not(.empty) + label,
.form-fancy .form-control[placeholder] + label,
.form-fancy .form-control:focus + label{
	top: -15px;
	font-size: 14px;
}

.has-feedback i.form-control-feedback{
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -7px;
}

#contact-team-form {
	select {
		margin-left: 0;

		&.error {
    		color: #333 !important;
    	}
	}
}

.input-transparent,
.input-transparent:focus {
    background: 0 none;
    border: 0 none;
    box-shadow: none;
}

// 
// Checkboxes
// 

// Checkbox slider
.checkbox-slider {
	position: relative;
	&.checkbox-slider-lg {
		height: $checkbox-size-lg;
		width: $checkbox-size-lg * 2;
		.form-check-label {
			padding-left: $checkbox-size-lg;
			line-height: $checkbox-size-lg;
		}
	}
	&.checkbox-slider-labels {
		input[type="checkbox"]+span {
			padding-left: 0;
		}
		.form-check-label {
			padding-left: 0;
			position: absolute;
		}
		.checkbox-label {
			position: absolute;
			color: $white;
			font-weight: bold;
			&.checkbox-label-on {
				padding-left: 11px;
				z-index: 2;
			}
			&.checkbox-label-off {
				padding-left: $checkbox-size + 5;
				z-index: 2;
			}
		}
		&.checkbox-slider-lg {
			.checkbox-label-off {
				padding-left: $checkbox-size-lg + 5;
			}
		}
	}
	input[type="checkbox"] {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 0%;
		margin: 0 0;
		cursor: pointer;
		opacity: 0;
		filter: alpha(opacity=0);
		+ span {
			padding-left: 2rem;
			user-select: none;
			&::before {
				content: "";
				position: absolute;
				display: inline-block;
				left: 0px;
				height: $checkbox-size;
				width: $checkbox-size * 2;
				z-index: 1;
				background: rgba(100, 100, 100, 0.4);
				transition: background 0.2s ease-out;
				border-radius: $checkbox-size;
				@at-root .checkbox-slider-lg#{&} {
					height: $checkbox-size-lg;
					width: 80px;
					border-radius: $checkbox-size-lg;
				}
			}
			&::after {
				content: "";
				position: absolute;
				display: block;
				left: 0px;
				top: 0;
				width: $checkbox-size;
				height: $checkbox-size;
				z-index: 5;
				transition: margin-left 0.1s ease-in-out;
				text-align: center;
				font-weight: bold;
				background: #ffffff;
				background-clip: padding-box;
				border: solid transparent 2px;
				border-radius: $checkbox-size;
				@at-root .checkbox-slider-lg#{&} {
					height: $checkbox-size-lg;
					width: $checkbox-size-lg;
					border-radius: $checkbox-size-lg;
				}
			}
			> h4 {
				display: inline;
			}
		}
		&:checked + span {
			&::before {
				background: #007CC6;
			}
			&::after {
				margin-left: $checkbox-size;
				border: solid transparent 2px;
				background-clip: padding-box;
				animation: ease-in 0.3s;
				@at-root .checkbox-slider-lg#{&} {
					margin-left: $checkbox-size-lg;
				}
			}
		}
		&:not(:checked) + span::after {
			animation: ease-in 0.3s;
		}
	}
}

//fund screener form fixes
#performance-filter-container {
	.form-control,
	.input-group-addon {
    	padding: .5rem;
	}
}


// Password strength plugin
.password_strength {
	padding: 0 5px;
	display: inline-block;
	width: 100%;
}
.password_strength_1 {
	background-color: #fcb6b1;
}
.password_strength_2 {
	background-color: #fccab1;
}
.password_strength_3 {
	background-color: #fcfbb1;
}
.password_strength_4 {
	background-color: #dafcb1;
}
.password_strength_5 {
	background-color: #bcfcb1;
}

.fund-search-box
{
	position: relative;
	flex: 1 0 auto;
	background: white;
	padding: 0.25rem 0.25rem;
	border: 1px solid #eee;

	.fund-search-input
	{
		background: transparent;
		outline: none;
		border: none;
		width: 100%;
	}

	.fund-search-input::placeholder
	{
		opacity: 1;
		color: #bebebe;
	}

	.fund-search-results
	{
		position: absolute;
		display: block;
		width: calc(100% + 1px);
       	max-height: 300px;
        overflow-x: hidden;
		overflow-y: scroll;
		top: 100%;
		left: -1px;
		z-index: 2;
		background: white;
		color: #333;
		border-left: 1px solid $light-grey;
		border-right: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;

		.fund-search-results-header
		{
			display: block;
			border-bottom: 1px solid $grey;
			width: 100%;
			margin: 1rem 0.5rem;
			padding-bottom: 0.25rem;	
		}

		.fund-search-results-items
		{
			margin: 0;
			padding-left: 0;
			list-style: none;

			li
			{
				display: block;
				text-decoration: none;
				padding: 0 0.5rem;
				height: 3rem;

				&:hover
				{
					background: $light-grey;
					cursor: pointer;
				}

				a
				{
					color: #333;
					text-decoration: none;
					display: flex;
					align-items: center;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.icon-search
	{
		color: #333;
	}
}

.rounded-info-box {
	padding: 1em;
	border: 1px solid #DCDFE3;
	border-radius: 0.5em;
	margin-bottom: 1.25em;

	.form-check {

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.form-control-xl,
.input-group-xl>.form-control,
.input-group-xl>.input-group-addon,
.input-group-xl>.input-group-btn>.btn {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    border-radius: 0;

	&.form-control-mfa {
		padding: 0;
		min-height: 64px;
	}
}

input.hide-number-arrows::-webkit-outer-spin-button,
input.hide-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.hide-number-arrows[type=number] {
  -moz-appearance: textfield;
}

/* This is from Bootstrap 4 full release, adding it here */
// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.5;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;

	&.form-control-sm,
	&.form-control-lg {
		padding-right: 0;
		padding-left: 0;
	}

	&:focus-visible {
		outline: transparent;
	}
}
.form-inline {
	@include media-breakpoint-up(sm) {
		// Make static controls behave like regular ones
		.form-control-plaintext {
			display: inline-block;
		}
	}
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
  
	> .col,
	> [class*="col-"] {
	  padding-right: 5px;
	  padding-left: 5px;
	}
  }

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

$form-feedback-valid-color:         #80BDFF !default;
$form-feedback-invalid-color:       $red !default;
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;

@mixin form-validation-state($state, $color) {
	.#{$state}-feedback {
		display: none;
		width: 100%;
		margin-top: $form-feedback-margin-top;
		font-size: $form-feedback-font-size;
		color: $color;
	}

	.#{$state}-tooltip {
		position: absolute;
		top: 100%;
		z-index: 5;
		display: none;
		max-width: 100%; // Contain to parent when possible
		padding: .5rem;
		margin-top: .1rem;
		font-size: .875rem;
		line-height: 1;
		color: #fff;
		background-color: rgba($color, .8);
		border-radius: .2rem;
	}

	.form-control,
	.custom-select {
		.was-validated &:#{$state},
		&.is-#{$state} {
		border-color: $color;

		&:focus {
			border-color: $color;
			box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
		}

		~ .#{$state}-feedback,
		~ .#{$state}-tooltip {
			display: block;
		}
		}
	}

	.form-check-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
			/* ~ .form-check-label {
				color: $color;
			} */

			~ .#{$state}-feedback,
			~ .#{$state}-tooltip {
				display: block;
			}
		}
	}

	.custom-control-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
		~ .custom-control-label {
			color: $color;

			&::before {
			background-color: lighten($color, 25%);
			}
		}

		~ .#{$state}-feedback,
		~ .#{$state}-tooltip {
			display: block;
		}

		&:checked {
			~ .custom-control-label::before {
			@include gradient-bg(lighten($color, 10%));
			}
		}

		&:focus {
			~ .custom-control-label::before {
			box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, .25);
			}
		}
		}
	}

	// custom file
	.custom-file-input {
		.was-validated &:#{$state},
		&.is-#{$state} {
		~ .custom-file-label {
			border-color: $color;

			&::before { border-color: inherit; }
		}

		~ .#{$state}-feedback,
		~ .#{$state}-tooltip {
			display: block;
		}

		&:focus {
			~ .custom-file-label {
			box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
			}
		}
		}
	}
}

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

.hide-ms-password-reveal::-ms-reveal {
	display: none;
}
.hide-safari-password-suggestion::-webkit-contacts-auto-fill-button,
.hide-safari-password-suggestion::-webkit-credentials-auto-fill-button {
	visibility: hidden;
	display: none !important;
	pointer-events: none;
	position: absolute;
	right: 0;
}

.show-hide-password-button {
	position: absolute;
    top: 32.8px;
    right: 0;
	outline: none !important;
    font-size: 21px;
    text-decoration: none !important;
	border: 1px solid rgba(0,0,0,0);
	padding: 0 6px;
	color: #006EAD !important;

	&:focus { 
		/* border: 1px solid #3eb8ff; */
		border: 1px solid transparent;
	}
	
	.putnamicon {
		vertical-align: -2px;
	}
}

/* Server Error */
.server-error-text {
	color: #95142b;
	display: none;
}
.server-error .server-error-text {
	display: block;
}
.server-error input.invalid {
	border-color: #95142b !important;
}
.server-error input.invalid:focus {
	border-color: #95142b !important;
	box-shadow: 0 0 0 0.2rem rgb(149 20 43 / 25%) !important;
}

/* Single digit input */
.single-digit-input {
	padding: 0;
	min-height: 37px;
	text-align: center;
}

/* Password requirements */


@keyframes requirementFadeOut {
	to {opacity: 0;}
}

@keyframes requirementFadeIn {
	to {opacity: 1;}
}

@keyframes requirementCollapse {
	to {max-height: 0;}
}

@keyframes requirementExpand {
	to {max-height: 50px;}
}

.password-requirements-progression {
	min-height: 50px;

	&.valid .password-requirement {
		display: none;
	}
	
	.password-requirement {
		display: flex;
		max-height: 50px;
		opacity: 1;

		.col {
			margin-bottom: 4px;
		}

		&:last-child {

			.col {
				margin-bottom: 0;
			}
		}

		.putnamicon {
			vertical-align: -1px;
			margin-right: 8px;
		}

		&.requirement-fulfilled {
			animation: requirementFadeOut 1s 2s, requirementCollapse 1s 2s;
			animation-fill-mode: forwards;

			.putnamicon {
				color: #009914;

				&.putnamicon-Circle:before {
					content: "\e991";
					font-size: 18.3px;
					margin-left: -1.8px;
					width: 16.8px;
					vertical-align: -1.5px;
					display: inline-block;				
				}
			}
		}
	}

	.password-valid {
		font-weight: 700;
		background-color: #ebf3eb;
		color: #318835;
		border-radius: 0.4em;
		padding-top: 11px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 1;

		.putnamicon {
			font-size: 1.5rem;
			vertical-align: -0.3rem;
			margin-right: 0.2rem;
		}
	}
}

/* Specific styles for authentication forms, needed for WCAG contrast or other accessibility issues */
.auth-modal-form {
	
	.alert-warning {
		color: #292B2C;
		background-color: #FFF3CD;
	}
}

.mfa-need-help-link-area {
	margin-bottom: 0;
	margin-top: 0.8rem;

	@include media-breakpoint-up( md ) {
		margin-left: 1.25rem;
	}
}

.fv-search
{
	display: flex;
	border: 1px solid #ccc;
	padding : 2px 6px;

	.twitter-typeahead
	{
		flex: 1;

		.tt-hint
		{
			visibility: hidden;
		}

		input
		{
			width: 100%;
		}
	}

	.tt-dropdown-menu
	{
		max-height: 300px;
		border: 1px solid #ddd;
		background: white;
		overflow-y: scroll;
		margin-left: -7px;
		margin-top: 2px;
		width: calc(100% + 44px);
		padding: 0 7px;

		.tt-category
		{
			margin: 5px 0;
			font-size: 16px;
			font-weight: 600;
			border-bottom: solid 1px #ddd;
		}
	}
}
//
// Text
//

// Alignment

// Responsive alignment

// Transformation

// Weight size and decoration
strong.text-large, .font-weight-bold 		{ font-weight: $font-weight-bold; }
.font-weight-bolder 						{ font-weight: $font-weight-bolder; }
.text-normal, .normal, .font-weight-normal 	{ font-weight: $font-weight-normal; }
.text-light, .font-weight-light				{ font-weight: $font-weight-light; }
.font-size-normal							{ font-size: $font-size-base; }
.text-small									{ font-size: $small-font-size; }
.text-large {
	font-size: rem(33);
	line-height: 40px;
	font-weight: $font-weight-light;
	margin: 0.4em 0 0.6em;
}
.text-underline {
	padding: 0.5rem 0;
	border-bottom: 1px solid $gray-400;
}
.text-decoration-none { text-decoration: none !important; } /* In BS 4.3. Remove after upgrade */
.text-header { font-size: rem(52); }
.text-disclosure {
	font-size: rem(14);
}
.sup-large	{
	top: -0.2em;
	font-size: 75%;
}
.text-lh-1	{ line-height: 1; }
.text-vertical {
	writing-mode: vertical-rl;
	-webkit-writing-mode: vertical-rl;
	padding-bottom: 7px;
	min-width: 27px;
}

.rotate-180 {
	transform: rotate(180deg);
}

// Contextual colors

@include text-emphasis-variant('.error', $brand-danger);
input.error {
	border: 1px solid #ff0000;
}

// Font color
@include text-emphasis-variant('.text-color-normal', $body-color);
@include text-emphasis-variant('.text-absolute-return', $color-absolute-return);
@include text-emphasis-variant('.text-asset-allocation', $color-asset-allocation);
@include text-emphasis-variant('.text-blend', $color-blend);
@include text-emphasis-variant('.text-global-sector', $color-global-sector);
@include text-emphasis-variant('.text-growth', $color-growth);
@include text-emphasis-variant('.text-income', $color-income);
@include text-emphasis-variant('.text-taxable-income', $color-income);
@include text-emphasis-variant('.text-tax-free-income', $color-income);
@include text-emphasis-variant('.text-value', $color-value);
@include text-emphasis-variant('.text-panagora', $color-panagora);

@include text-emphasis-variant('.text-teal', $teal);

// Links
.link-unstyled, .link-unstyled:link, .link-unstyled:hover, .link-unstyled:focus {
	color: inherit;
	text-decoration: inherit;
}

// Blockquotes
.blockquote {
	position: relative;

	.hang-left {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		text-indent: -0.7rem;
	}
}

// Old
.doctype {
	font-size: 0.8em;
	text-transform: uppercase;
	color: #757575;
	vertical-align: 0.1em;
}
.upper	{ text-transform: uppercase; }
.wrap	{ white-space: normal; }
.nobr	{ white-space: nowrap; }
// Custom variables with mixins included to import on pages
@import 'mixins';

// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.


//
// Colors
//

$white: #ffffff;
$red: #95142b; // Used for brand-danger/.text-danger
$orange: #f89500; // Used for brand-warning/.text-warning
$yellow: #fbaf17;
$green: #318835; // Used for brand-success/.text-success
$blue: #0078BD; // Used for brand-primary/.text-primary
$purple: #903f97;
$teal: #005660;
$teal-dark: #00444C;

$grey: #999;
$gray: $grey;
$light-grey: #eee;
$light-gray: $light-grey;
$middle-grey: #ccc;
$middle-gray: $middle-grey;
$light-black: #333;
$gray-650:#777777;

$fund-orange:   #ff6600;
$fund-yellow:   #ff9900;
$fund-purple:   #663366;
$fund-blue:     #0066cc;

$color-absolute-return:	#C24E00; // Changed from #FF6600 for accessibility
$color-asset-allocation:#663366;
$color-blend:			#0066CC;
$color-global-sector:	#448f9c;
$color-growth:			#009900;
$color-income:			#ff9900;
$color-value:			#cc0000;
$color-panagora:		#00703c;
$color-tax-free:		#ff9900;
$color-taxable-income:	#ff9900;

$color-sustainability:#008A00;

//Always Active campaign colors
$color-aware:		#4B9538; // sustainability
$color-inspired:	#0f4880; // equities
$color-diligent:	#DCAC3E; // income
$color-aligned:		#5F3894; // allocation

$facebook:      #3b5998;
$twitter:       #2aa9e0;
$googlePlus:    #dd4b39;
$youtube:       #bb0000;
$linkedin:      #007bb5;
$instagram:     #125688;

$text-muted: #999999;

/// Morningstar Grey
$morningstar:   #292b2c;
$morningstarOrange: #FE9914;

//HWI colors
$asset-allocation-icons: #4d1966;
$equity-income-red: #990000;
$growth-opps-green: #60bb46;
$hwi-bg-blue:	#ECF4F9;
$hwi-bg-light-grey: #f4f4f4;
$hwi-msi-orange: #f6a827;

// Social colors
$s1-blue: #007dc5;
$s1-blue-2:#0066b3;
$s2-blue: #00acbe;
$s2-blue-2: #005f6d;
$s3-green: #62bb46;
$s3-green-2: #275b20;
$s4-blue:#0066b3;

//
// Links
//

$link-color:        #064bad;
$link-hover-color:  #064bad;

//
// Fonts
//

$browser-size-context:	16;
$font-family-sans-serif: 'Source Sans Pro', 'seravek', 'segoe ui', 'roboto', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
$font-size-base:    rem(15);
$line-height-base:	1.666;

$font-weight-light:		300;
$font-weight-normal:	400;
$font-weight-bold:		600;
$font-weight-bolder:	800;

$font-size-h1:		rem(28);
$font-size-h1-sm:	rem(32);
$font-size-h2:		rem(22);
$font-size-h3:		rem(21);
$font-size-h4:		rem(18);
$font-size-h5:		rem(16);
$font-size-h6:		$font-size-base;

$headings-font-weight:   $font-weight-normal;

$display1-size: 2.75rem;
$display2-size: 2.5rem;
$display3-size: 2.25rem;
$display4-size: 2rem;

$display1-weight:	$font-weight-light;
$display2-weight:	$font-weight-light;
$display3-weight:	$font-weight-light;
$display4-weight:	$font-weight-light;

//
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-sm-cell-padding: .5rem;
$table-xs-cell-padding: .3rem;

$table-blue:            #007DC5;
$table-dark-blue:       #002957;
$table-grey:            #666666;

$table-border-color:	#ccc;
$table-bg-accent:		#FAFAFA;
$table-bg-blue-accent:  #ecf4f9;
$table-bg-green-accent: #ecf9f4;
$table-bg-hover:		#F4F5F8;

//
// Buttons
//

$btn-order-color:			$white;

$btn-blue-order-bg:			#2278c9;
$btn-blue-order-border:		#2278c9;

$btn-green-order-bg:		#157e00;
$btn-green-order-border:	#157e00;

$btn-custom-green-bg:		#3d8b38;
$btn-custom-green-border:	#2f7a2c;
$btn-custom-green-color:    $white;

$btn-light-green-bg:		#7ED321;
$btn-light-green-border:	#7ED321;
$btn-light-green-color:	    $white;

$btn-gray-order-bg:			#aaa;
$btn-gray-order-border:		#aaa;

$btn-red-order-bg:          #ec2e40;
$btn-red-order-border:      #ec2e40;

$btn-orange-order-bg:       #f4802b;
$btn-orange-order-border:   #f4802b;

$btn-purple-order-bg:       #885aa2;
$btn-purpler-order-border:  #885aa2;

$btn-aqua-order-bg:         #11acbc;
$btn-aqua-order-border:     #11acbc;

$btn-yellow-order-bg:		#fbad2b;
$btn-yellow-order-border:	#fbad2b;

$btn-dead-order-bg:     #727272;
$btn-dead-order-border: #6b6b6b;

$btn-dark-grey-order-bg:     #464748;
$btn-dark-grey-order-border: #6b6b6b;

$btn-lime-green-order-bg:     #83d03d;
$btn-lime-green-order-border: #83d03d;

$btn-black-bg:     			#444444;
$btn-black-border: 			#333333;



// 
// Forms
// 
$input-color: #464a4c;
$form-check-input-margin-y: .2rem;

$checkbox-size:20px;
$checkbox-size-lg:40px;

//
// Modals
//

$modal-backdrop-opacity: .4;

//
// Popovers
//

$popover-max-width: 500px;

//
// Components
//

$border-radius:     0;
$border-radius-lg:  0;
$border-radius-sm:  0;
$custom-checkbox-radius: .25rem;

//
// Easing
//

$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1,0,0,1);

$authenticMotion: cubic-bezier(.4,0,.2,1);

//
// Carousel
//

$carousel-transition:	transform .5s ease-in-out !default;

$carousel-control-color: $white;
$carousel-control-color-gray: '%23ccc';
$carousel-control-color-blue: $link-color;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-prev-icon-bg-gray: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color-gray}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-next-icon-bg-gray: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color-gray}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-prev-icon-bg-blue: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color-blue}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-next-icon-bg-blue: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='#{$carousel-control-color-blue}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");


// Load bootstrap variables and mixins to keep everything in a single file (custom.scss)
@import "../frameworks/bootstrap-4.0.0-alpha.6/scss/mixins",
        "../frameworks/bootstrap-4.0.0-alpha.6/scss/variables",
        "variables-updates";


button {
	cursor: pointer;
}
// Button Addons

.btn-addon-group {
	padding: 0;
	display: inline-flex;
	.btn-addon-content{
		border-left:solid 1px #ccc;
		padding: $btn-padding-y $btn-padding-x;
		&:first-child{
			border-left: 0 none;
			&::before {
				vertical-align: -1px;
			}

		}
	}
	
	&.btn-addon-dark {
		.btn-addon-content {
			border-left: 0;
			&:first-child{
				background: rgba(0,0,0,0.2);
			}
		}
	}
	&.btn-block {
		.btn-addon-content {
			&:last-child{
				flex-grow: 1;
			}
		}
	}
	&.btn-lg {
		.btn-addon-content {
			padding: $btn-padding-y-lg $btn-padding-x-lg;
		}
	}
	&.btn-sm {
		.btn-addon-content {
			padding: $btn-padding-y-sm $btn-padding-x-sm;
		}
	}
}

.lit-btn-add-order {
	color: $btn-order-color;
	background: $btn-green-order-bg;
	border: $btn-green-order-border;
}

.lit-btn-view-order {
	color: $btn-order-color;
	background: $btn-blue-order-bg;
	border: $btn-blue-order-border;
}

.lit-button-order-gray {
	color: $btn-order-color;
	background: $btn-gray-order-bg;
	border: $btn-gray-order-border;
}

.lit-btn-loading-add-order {
	background: $btn-green-order-bg url("/static/img/advisor/loading.gif") no-repeat 50% 50%;
	background-size: 18%;
	height: 28px;
	width: 120px;
}

.lit-btn-loading-view-order {
	background: $btn-blue-order-bg url("/static/img/advisor/loading.gif") no-repeat 50% 50%;
	background-size: 18%;
	height: 28px;
	width: 110px;
}

//
// Button Text-Wrap
//

.btn-wrap {
	@include media-breakpoint-down (lg) {
		white-space: normal !important;
	}
} 

     
//
// Alternate buttons
//

.btn-custom-green {
	@include button-variant($btn-custom-green-color, $btn-custom-green-bg, $btn-custom-green-border);
}
.btn-light-green {
	@include button-variant($btn-light-green-color, $btn-light-green-bg, $btn-light-green-border);
}
.btn-teal {
	@include button-variant($white, $teal-dark, $teal);
}
.btn-growth {
	@include button-variant($white, $color-growth, $color-growth);
}
.btn-income,
.btn-taxable-income {
	@include button-variant($white, $color-income, $color-income);
}
.btn-blend {
	@include button-variant($white, $color-blend, $color-blend);
}
.btn-panagora {
	@include button-variant($white, $color-panagora, $color-panagora);
}
.btn-global-sector {
	@include button-variant($white, $color-global-sector, $color-global-sector);
}
.btn-tax-free,
.btn-tax-free-income {
	@include button-variant($white, $color-tax-free, $color-tax-free);
}  
.btn-absolute-return {
	@include button-variant($white, $color-absolute-return, $color-absolute-return);
}
.btn-value-red,
.btn-value {
	@include button-variant($white, $color-value, $color-value);
}
.btn-asset-allocation {
	@include button-variant($white, $color-asset-allocation, $color-asset-allocation);
}
.btn-sector{
	@include button-variant($white, $btn-aqua-order-bg, $btn-aqua-order-border);
}
.btn-dead{
	@include button-variant($white, $btn-dead-order-bg, $btn-dead-order-border);
}
.btn-dark-grey{
	@include button-variant($white, $btn-dark-grey-order-bg, $btn-dark-grey-order-border);
}
.btn-fund-visualizer {
	@include button-variant($white, $btn-lime-green-order-bg, $btn-lime-green-order-border);
}
//
// Contextual backgrounds
//

@include bg-variant('.bg-white', $white);
@include bg-variant('.bg-teal', $teal);

.bg-blurred {
	background-color: rgba(255,255,255,0.9);
}
/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.bg-blurred {
		background-color: rgba(255,255,255,0.5);
		-webkit-backdrop-filter: blur(13px);
		backdrop-filter: blur(13px);
	}
}
.fund-added-alert {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 101;
	background-color: #318835;
	border: 0;
	color: #FFF;
	font-weight: 600;
	padding-top: 17px;

	a {
		color: #FFF;
		text-decoration: underline;
	}

	.close {
		opacity: 1;
		padding: .75rem 1.25rem 0 1.25rem;

		&:focus {
			outline: 0;
		}

		&:hover {
			color: #FFF;
		}
	}
}
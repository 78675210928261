#back-to-top{
	display: none;

	@include media-breakpoint-up(sm){
		position: fixed;
		bottom: 25px;
		right: 25px;
		width: 40px;
		height: 40px;
		padding-top: 3px;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;
		color: #007CC6;
		border: solid 1px #d9d9d9;
		background-color: $white;
		box-shadow: 1px 2px 10px rgba(0,0,0,0.2);
		transition:all 0.2s;

		&:hover {
			height: 45px;
			.putnamicon {
				top: 2px;
			}
			.back-text {
				bottom:5px;
				opacity: 1;
			}
		}
	
		.putnamicon{
			position: absolute;
			top: 7px;
			left: 0;
			width: 100%;
			font-size: 1.4em;
			transition:all 0.2s;
		}

		.back-text {
			position: absolute;
			bottom: -3px;
			left: 0;
			width: 100%;
			opacity:0;
			font-size: .8em;
			transition:all 0.2s;
		}
	}
}

.sticky #back-to-top {
	@include media-breakpoint-up(sm) {
		display: block;
	}
}

.dcio #back-to-top {
	@include media-breakpoint-up(sm) {
		.putnamicon {
			top: 3px;
		}
		&:hover {
			.putnamicon {
				top: 3px;
			}
			.back-text {
				bottom:2px;
			}
		}
	}
}
.tooltip {
	&.tooltip-wider {
		.tooltip-inner {
			max-width: 310px;
		}
	}
}

.tooltip-inner {
	&.no-padding {
		padding: 0;
	}
}

.tooltip-light {
	.tooltip-inner {
		background-color: #fff;
		color: #000;
		border: 1px solid #ccc;
		text-align: left;

		.tooltip-header {
			background-color: #f5f5f5;
			border-bottom: 1px solid #ccc;
			padding: 5px 10px;
			font-weight: bold;
		}
		.tooltip-body {
			padding: 5px 10px;
		}
	}

	&.show {
		opacity: 1;
	}

	&.tooltip-left .tooltip-inner,
	&.bs-tether-element-attached-right .tooltip-inner::before {
		width: 8px;
		height: 8px;
		background-color: #FFF;
		border-left: 1px solid transparent;
		border-top: 1px solid #CCC;
		border-right: 1px solid #CCC;
		border-bottom: 1px solid transparent;
		transform: rotate(45deg);
		margin-top: -5px;
		right: 2px;
	}
}
@import "../../custom";

// Remove when upgrading Bootstrap
.border          { border: 1px solid $gray-400 !important; }
.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }


// Table Borders

.table.table-no-borders>thead>tr>th,
.table.table-no-borders>tbody>tr>th,
.table.table-no-borders>tfoot>tr>th,
.table.table-no-borders>thead>tr>td,
.table.table-no-borders>tbody>tr>td,
.table.table-no-borders>tfoot>tr>td,
.table.table-no-borders tr:last-child{
	border-top:none;
	border-bottom:none;
}

.bordered-grid {
	
	> div {
		padding: 15px;
		border-top: 1px solid #CCC;
		border-bottom: 1px solid #CCC;
		margin-bottom: -1px;

		@include media-breakpoint-up( sm ) {
			border: 1px solid #CCC;
			margin-left: -1px;
		}
	}
}

.bordered-columns,
.bordered-columns-xs {
	&>[class^="col-"],
	&>[class*="col-"] {
		border-left: solid 1px #ddd;
	}
	&>[class^="col-"]:first-child,
	&>[class*="col-"]:first-child {
		border-left: 0 none;
	}
}

.border-black {
	border-color: $gray-600 !important;
}

.border-dotted {
	border-style: dotted !important;
}
.border-dotted-white, .dot-rule{
	border-top:1px dotted #d6d6d6;
}

.carousel-content-divider{
	@include media-breakpoint-up( md ) {
		&-md
		{
			border-right: 1px solid rgba( 256, 256, 256, 0.5 );
		}
	}
}

//
// Border-radius
//
// Overwrite of custom.scss to apply to rounded utility classes only. BS v4.alpha.6 classes
$border-radius: 0.25rem;

.rounded {
	border-radius: $border-radius !important;
}

.rounded-top {
	border-top-left-radius: $border-radius !important;
	border-top-right-radius: $border-radius !important;
}

.rounded-right {
	border-top-right-radius: $border-radius !important;
	border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
	border-bottom-right-radius: $border-radius !important;
	border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
	border-top-left-radius: $border-radius !important;
	border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
	border-radius: 50% !important;
}
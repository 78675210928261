
/* Fixed column table */
.scroll-table table {
	min-width: 900px;
	border-collapse: separate;
}
@media (max-width:991px) {
	.scroll-table{
		position: relative;

		#data-tables
		{
			overflow-x: scroll;
		}
	}
	.scroll-gradient-left, .scroll-table::after{
		content:'';
		position: absolute;
		top:0;
		height: 100%;
		width:20px;
		z-index: 5;
		pointer-events: none;
		opacity: 0;
		transition: 0.2s ease-out;
	}
	.scroll-table.scrolled .scroll-gradient-left{
		left:0;
		opacity: 1;
		background: linear-gradient(to left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	}
	.scroll-table::after{
		right: 0;
		opacity: 1;
		background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	}
	.scroll-table.scroll-end::after{
		opacity: 0;
	}
	.scroll-table table {
		margin:0;
	}
}

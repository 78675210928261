.highlight-box {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		content: '';
		display: block;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #F8F8F8;
		border: 3px solid #C4C4C4; 
	}

	&.highlight-box-blue {
		&::before {
			background-color: #F8FCFD;
			border: 3px solid #C4D6E7; 
		}
	}

	&.highlight-box-left-open {
		&::before {
			left: 15px;
			width: calc(100% - 15px);
			border-left: 0;
		}
	}

	&.highlight-box-y-padding {
		&::before {
			top: -10px;
			height: calc(100% + 20px);
		}
	}
}
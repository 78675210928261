/* Placeholder animation */
/* Example usage
<div class="animate-placeholder-text">
    <div class="col-7 pt-2"></div>
    <div class="col-12 pt-2"></div>
    <div class="col-3"></div>
    <div class="col-12"></div>
    <div class="col-1"></div>
    <div class="col-12"></div>
    <div class="col-5"></div>
    <div class="col-12"></div>
</div>  */
@keyframes placeHolderShimmer {
	0% {
		right: 0;
	}
	100% {
		right: -200%;
	}
}
.animate-placeholder-text {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		width: 300%;
		height: 100%;
		z-index: 1;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: #f6f7f8;
		background: #eeeeee;
		background: linear-gradient(to right, #eeeeee 34%, #dddddd 40%, #eeeeee 46%);
	}
	> div {
		z-index: 2;
		height: 10px;
		flex-basis: auto;
		background-color: #fff;

		&:last-child{
			flex: 1;
		}
	}
}
@import "utilities/align",
		"utilities/background",
		"utilities/borders",
		"utilities/browser-compatibility",
		"utilities/dimmable",
		"utilities/flex",
		"utilities/image",
		"utilities/old", // Old styles brought from main.css and needed during template conversion. Delete once template is fully updated.
		"utilities/position",
		"utilities/spacing",
		"utilities/table",
		"utilities/tooltip",
		"utilities/text",
		"utilities/visibility";

/* This is from another version of Bootstrap (I think newer?), fixes some IE bugs.  Delete when we get there */
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.overflow-hidden {
	overflow: hidden;
}
.overflow-visible {
	overflow: visible !important;
}
.overflow-scroll {
	overflow: auto;
}
/* END Deletable area */

/* BlueConic removes this class when the visitor is confirmed NOT to be an Advisor */
.hidden-from-advisors {
	display: none;
}

/* See the MagnifyImages() function for usage */
.magnify {
	position: relative;
	overflow: hidden;
	transition: box-shadow 0.25s;

	&:hover {
		box-shadow: 0 0 5px rgba(0,0,0,0.3);
	}
}
.magnify-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
/* Fund Box Highlight styles */
/* Example usage
	<#include '/include/content/webcasts/single-wide-band.ftl'>
 */

.fund-box-feature {
	background-color: #f4f4f4;
	border-top: 4px solid #CCC;

	&.absolute-return {
		border-color: $color-absolute-return;
	}
	&.asset-allocation {
		border-color: $color-asset-allocation;
	}
	&.blend {
		border-color: $color-blend;
	}
	&.global-sector {
		border-color: $color-global-sector;
	}
	&.growth {
		border-color: $color-growth;
	}
	&.income {
		border-color: $color-income;
	}
	&.value {
		border-color: $color-value;
	}
	&.panagora {
		border-color: $color-panagora;
	}
	&.tax-free-income {
		border-color: $color-tax-free;
	}
	&.taxable-income {
		border-color: $color-taxable-income;
	}
	
}
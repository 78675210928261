/* --  Styles taken from Bootstrap 4.0 - Delete once upgraded -- */

// Common values

// Sass list not in variables since it's not intended for customization.
$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
	.position-#{$position} { position: $position !important; }
}

// Shorthand

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: $zindex-fixed;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $zindex-fixed;
}

.sticky-top {
	@supports (position: sticky) {
		position: sticky;
		top: 0;
		z-index: $zindex-sticky;
	}
}

/* -- */

/* Must provide your own width and height for this to work */
.absolute-center {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.waiting-overlay {
	background:#fff url("/static/img/spinner.gif") no-repeat scroll 50% 50%;
	height:100%;
	left:0;
	opacity:0.5;
	position:absolute;
	top:0;
	width:100%;
	z-index:3;
}

.putnam-loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}
.putnam-loader-sm {
	border: 5px solid #f3f3f3;
	border-top: 5px solid #888;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}
.putnam-loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	&.putnam-loader-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 2;
	}

	.putnam-loader,
	.putnam-loader-sm {
		margin: 0 auto;
	}
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
/* Fat site search */
.fat-search {
	background-color: #FFF;
	border-bottom: 1px solid #CCC;

	form {
		padding: 3rem 0 2rem;
	}

	button {

		&.btn-lg {
			width: 100%;
			height: 100%;
		}
	}

	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px white inset;
	}

	&.modal-treatment {
		position: absolute;
		z-index: 2000;
		width: 95%;
		max-width: 1200px;
		left: 0;
		right:0;
		top: 15vh;
		margin: 0 auto;
		margin-left:auto;
		padding-left: 20px;
		padding-right: 20px;
	}

	.col-form-label {
		font-weight: 300;
		font-size: 26px;
	}

	.fat-search-input {
		border-width: 1px;
		color: $primary;
		font-weight: 400;
	}
}

.search-modal {
	position: absolute;
	z-index: 1999;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.4);
}

.fat-nav-results {

	ul {
		list-style-type: none;
		padding-left: 0;

		li {
			font-size: 1rem;
			margin-bottom: 0.5rem;
		}
	}
}

#mobile-fat-search-form {
	opacity: 1 !important;
}

.mobile-search {
	background-color: #FFF;
	border-bottom: 1px solid #CCC;

	input.form-control {
		border: 0;
		padding: 1rem 15px;
		font-size: 1.125rem;
	}

	.mobile-fat-search-link {
		display: block;
		padding: 0.3rem 0.7rem 0.22rem;
		font-size: 1.7rem;
		color: #787878;
		text-decoration: none;
	}
}

.start-search {

	&.active {

		.putnamicon {
			font-size: 0.8em;

			&::before {
				content: "\e61e";
			}
		}
	}

	&:hover,
	&:focus {
		text-decoration: none !important;
	}
}
.basic-header .start-search {
	position: absolute;
	bottom: 0;
	right: -1px;
	font-size: 1.2rem;
}

.search-results #main {
	background-color: white;
}

.fat-nav-bar,
.slim-nav-bar {

	.start-search {

		span {
			font-size: 20px !important;
			vertical-align: -3px;
		}

		&.active {

			span {
				font-size: 0.9rem;
				vertical-align: -1px;
			}
		}
	}
}


// 
// ADD OLD STYLES HERE
// Delete after template has been fully converted
// 

/* OLD main.css STYLES */
.hidden{
	display: none !important;
}
.hide-me{
	display:none !important;
}
/* Bootstrap 4 classes, from alpha v2 */
/* This and the next section from alpha v6 will be deleted when we migrate to new layout */
/* LOL, instead, we're keeping this and deleting it when we go to the NEWER padding and margin structure */
.m-t-0,
.m-y-0 {
	margin-top: 0!important
}
.m-b-0,
.m-y-0 {
	margin-bottom: 0!important
}
.p-r-0,
.p-x-0 {
	padding-right: 0!important
}
.p-l-0,
.p-x-0 {
	padding-left: 0!important
}
.p-t-0,
.p-y-0 {
	padding-top: 0!important
}
.p-b-0,
.p-y-0 {
	padding-bottom: 0!important
}
.m-x-auto {
	margin-right: auto!important;
	margin-left: auto!important
}
.m-r-0,
.m-x-0 {
	margin-right: 0!important
}
.m-l-0,
.m-x-0 {
	margin-left: 0!important
}
.m-a-0 {
	margin: 0!important
}
.m-r-1,
.m-x-1 {
	margin-right: 1rem!important
}
.m-l-1,
.m-x-1 {
	margin-left: 1rem!important
}
.m-t-1,
.m-y-1 {
	margin-top: 1rem!important
}
.m-b-1,
.m-y-1 {
	margin-bottom: 1rem!important
}
.m-a-1 {
	margin: 1rem!important
}
.m-r-2,
.m-x-2 {
	margin-right: 1.5rem!important
}
.m-l-2,
.m-x-2 {
	margin-left: 1.5rem!important
}
.m-t-2,
.m-y-2 {
	margin-top: 1.5rem!important
}
.m-b-2,
.m-y-2 {
	margin-bottom: 1.5rem!important
}
.m-a-2 {
	margin: 1.5rem!important
}
.m-r-3,
.m-x-3 {
	margin-right: 3rem!important
}
.m-l-3,
.m-x-3 {
	margin-left: 3rem!important
}
.m-t-3,
.m-y-3 {
	margin-top: 3rem!important
}
.m-b-3,
.m-y-3 {
	margin-bottom: 3rem!important
}
.m-a-3 {
	margin: 3rem!important
}
.p-a-0 {
	padding: 0!important
}
.p-r-1,
.p-x-1 {
	padding-right: 1rem!important
}
.p-l-1,
.p-x-1 {
	padding-left: 1rem!important
}
.p-t-1,
.p-y-1 {
	padding-top: 1rem!important
}
.p-b-1,
.p-y-1 {
	padding-bottom: 1rem!important
}
.p-a-1 {
	padding: 1rem!important
}
.p-r-2,
.p-x-2 {
	padding-right: 1.5rem!important
}
.p-l-2,
.p-x-2 {
	padding-left: 1.5rem!important
}
.p-t-2,
.p-y-2 {
	padding-top: 1.5rem!important
}
.p-b-2,
.p-y-2 {
	padding-bottom: 1.5rem!important
}
.p-a-2 {
	padding: 1.5rem!important
}
.p-r-3,
.p-x-3 {
	padding-right: 3rem!important
}
.p-l-3,
.p-x-3 {
	padding-left: 3rem!important
}
.p-t-3,
.p-y-3 {
	padding-top: 3rem!important
}
.p-b-3,
.p-y-3 {
	padding-bottom: 3rem!important
}
.p-a-3 {
	padding: 3rem!important
}
.m-t-4,
.m-y-4 {
	margin-top: 4rem!important
}
.m-b-4,
.m-y-4 {
	margin-bottom: 4rem!important
}
.m-t-5,
.m-y-5 {
	margin-top: 5rem!important
}
.m-b-5,
.m-y-5 {
	margin-bottom: 5rem!important
}

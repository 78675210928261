//
// Basic table
//

.table {
	thead {
		tr:first-child {
			th, td {
				border-top:0 none;
			}
		}
	}
	tbody {
		tr{
			&:last-child {
				border-bottom:1px solid #ccc;
			}
		}
	}
}

.table-fixed {
	table-layout:fixed;
}

//
// Extra condensed table w/ 1/3 padding
//

.table-xs {
  th,
  td {
    padding: $table-xs-cell-padding;
  }
}

// Custom green table
.table-green {
	thead th {
		border-bottom: 0;
		background-color: $color-sustainability;
		color: $white;
	}
	th, td {
		border-top: 0;
	}
	tbody {
		tr:last-child {
			border-bottom: 0;
		}
	}
	&.table-hover {
		tbody {
			tr:hover {
				background-color: $gray-200;
			}
		}
	}
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $table-bg-blue-accent;
	}
}

// Custom blue table
.table-blue {
	thead th {
		border-bottom: 0;
		background-color: $table-blue;
		color: $white;
	}
	th, td {
		border-top: 0;
	}
	tbody {
		tr:last-child {
			border-bottom: 0;
		}
	}
	&.table-hover {
		tbody {
			tr:hover {
				background-color: $gray-200;
			}
		}
	}	
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $table-bg-blue-accent;
	}
}

// Custom dark blue table
.table-dkblue {
	thead th {
		border-bottom: 0;
		background-color: $table-dark-blue;
		color: $white;
	}
	th, td {
		border-top: 0;
	}
	tbody {
		tr:last-child {
			border-bottom: 0;
		}
	}
	&.table-hover {
		tbody {
			tr:hover {
				background-color: $gray-200;
			}
		}
	}
		
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $table-bg-blue-accent;
	}
}

// Custom grey table
.table-grey {
	thead th {
		border-bottom: 0;
		background-color: $table-grey;
		color: $white;
	}
	th, td {
		border-top: 0;
	}
	tbody {
		tr:last-child {
			border-bottom: 0;
		}
	}
	&.table-hover {
		tbody {
			tr:hover {
				background-color: $gray-200;
			}
		}
	}
		
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $table-bg-blue-accent;
	}
}

/* sort styles */
.sort-head
{
	cursor: pointer;
}
.sort-arrows {
	display: inline-block;
	width: 10px;
	height: 12px;
	margin: 0 0 0 3px;
    vertical-align: -2px;
	background-repeat: no-repeat;
  	background-image: url("/static/img/table-sort-arrows.gif");
  	cursor: pointer;

  .headerSortUp &
  {
    background-image: url("/static/img/table-sort-arrow-up.gif");
  }

  .headerSortDown &
  {
    background-image: url("/static/img/table-sort-arrow-down.gif");
  }
}

#search_result_list
{
	table
	{
		table-layout: fixed;
		width: 100%;
	}
}

.wps tr.odd{
	background-color: #fafafa;
}

table tr.odd{
	background-color: #fafafa;
}

#crClientSummary #data-tables table#pricing-table {
    min-width: auto;
}

.stacky-table
{
	@include media-breakpoint-down( sm )
	{
		display : block;

		thead, tbody, tr, td, th
		{
			display : block;
		}
	}

	&-md
	{
		@include media-breakpoint-down( md )
		{
			display : block;

			thead, tbody, tr, td, th
			{
				display : block;
			}
		}
	}
}

table
{
	&.nowrap
	{
		td, th
		{
			white-space: nowrap;
		}

		&-md
		{
			@include media-breakpoint-down( md )
			{
				td, th
				{
					white-space: nowrap;
				}
			}
		}
	}

	// This fixes the border-collapse bug that exists in FF, Edge, and IE11
	// It's effectively the same as border-collapse: collapse ...except it works on more than one browser
	&.border-collapse
	{
		border-collapse: separate;
		border-spacing: 0px;
	}
}

// Materiality table
.materiality-icon {
	margin-top: -65px;
	margin-bottom: 0.2rem;
	max-width: 100px;
}

.table-materiality {
	overflow: hidden;

	tr {
		border-bottom: solid 1px #e9e9e9;
	}

	td {
		border: solid 1px #e9e9e9;

		&:nth-child(n+3) {
			width: 7.7%;
		}
	}
}

.materiality-key {
	margin-right: 15px;

	.materiality-key-box {
		display: inline-block;
		width: 12px;
		height: 12px;
		margin-right: 5px;
	}
}

.bg-ur {
	background-color: #00254D;
}

.bg-or {
	background-color: #0072BD;
}

.bg-sr {
	background-color: #B4B6B8;
}
.global-alert {
	display: none;
	overflow: hidden;
	background-color: $gray-100;
	border-bottom: solid 1px $gray-400;

	.global-alert-close-icon {
		position: absolute;
		display: block;
		top: -16px;
		right: 0;
		padding: 0;
		cursor: pointer;
		width: 45px;
		height: 45px;
		text-align: center;
		line-height: 45px;
		font-size: 45px;
		text-decoration: none;
		color: $gray-700;
		overflow: hidden;
	}

	&.global-alert-toast {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		background-color: transparent;
		pointer-events: none;

		& > .container {
			pointer-events: auto;
			padding: 0;
			background-color: #F4F1F1;
			max-width: 950px;
			border-left: 2px solid #D8D6D5;
			border-right: 2px solid #D8D6D5;
			border-top: 2px solid #D8D6D5;
		}

		&.burnt-toast > .container {
			background-color: #022943;
			border-color: #022943;
			color: #FFF;

			p a:link,
			p a:visited,
			p a:hover,
			p a:active {
				color: #89C5F6;
			}

			.doctype {
				color: #CCC;
			}

			.global-alert-close-icon {
				color: #FFF;
			}
		}
		
	}

	&.diversity {
		background-image:url("/static/img/divincl_message.jpg?v=1");
		background-position:center;
		background-size: cover;

		.global-alert-header {
			padding: 0.6rem;
			background-color: $white;

			@include media-breakpoint-up( md ) {
				p {
					font-size: rem(19);
					text-align: center;
				}
			}
		}
	}
}
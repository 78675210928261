body {
	background-color: #F5F5F5;
}
header.basic-header,
section.basic-content {
	background-color: #FFF;
}
 
#main {
	ul li,
	ol li {
    	margin-bottom: 0.3em;
	} 
}

.gradient-sidebar {
	border: 1px solid #d5d5d5;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
	padding: 5px 15px 30px;
}
ul{
	padding: 0 0 0 20px;

	&.list-left
	{
		padding-left: 18px;
	}
	&.large-items
	{
		li
		{
			padding-bottom : 0.5rem;
			padding-top : 0.5rem;
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}
.modal-btn {
	margin-left: 10px;
}
.basic-content {
	padding-bottom: 30px;
	padding-top: 1px;
}
.col-sm-offset-2 {
	margin-left: 16.666666666666664%;
}
.col-sm-offset-1 {
	margin-left: 8.333333333333332%;
}
.pdf-link
{
	.footnote
	{
		color : initial;
	}
}

.border-{
	&bottom{
		border-bottom: 1px solid #ccc;

		&.dotted{
			border-bottom: 1px dotted #ccc;
		}
	}
	&top{
		border-top: 1px solid #ccc;

		&.dotted{
			border-top: 1px dotted #ccc;
		}
	}
	&right{
		border-right: 1px solid #ccc;

		&.dotted{
			border-right: 1px dotted #ccc;
		}
	}
	&left{
		border-left: 1px solid #ccc;
		&-lg{
			@include media-breakpoint-up(lg){
				border-left: 1px solid #ccc;
			}
		}
		&.dotted{
			border-left: 1px dotted #ccc;
		}
	}
}

.wholesaler-contact .card {
    padding: 20px;
    display: block;
}

.footnote {
    font-size: 14px;
    color: #666;
}

/*Glossary Styles*/
.glossary{
	border-bottom:1px dashed #333;
	cursor:pointer;
		&:focus{
		outline: none;
	}
}

//Wireframe Properties

[data-wf] {
	outline: 2px solid gray;
   	position: relative; z-index: 1;
}

[data-wf]::before {
   position: absolute; z-index: 8675309;
   top: 0; bottom: 0; right: 0; left: 0;
   background:
      linear-gradient(to top right,
         transparent calc(50% - 1px), gray calc(50% - 1px),
         gray calc(50% + 1px), transparent calc(50% + 1px)),
      linear-gradient(to bottom right,
         transparent calc(50% - 1px), gray calc(50% - 1px),
         gray calc(50% + 1px), transparent calc(50% + 1px));
      content: attr(data-wf);
      color: gray;
      text-shadow:
         0 0 0.25em #F7F6F1BB, 0 0 0.25em #F7F6F1BB,
         0 0 0.25em #F7F6F1BB, 0 0 0.25em #F7F6F1BB,
         0 0 0.25em #F7F6F1BB;
      display: flex; 
      justify-content: center;
      align-items: center;
}

// /*Expand-collapse styles*/
// .text-expand {
// 	color: $expandCollapse-color;
// }
// .text-collapse {
// 	color: $expandCollapse-color;
// }



/* Franklin Templeton acquisition banner */
.ft-acquisition-banner { 
	background-image: url('/static/img/hp_barrons-2024_alt_sm.jpg');
	background-size: cover;
	background-position: center right;
	background-repeat: no-repeat;
	
	@include media-breakpoint-up( md ) {
		background-image: url('/static/img/hp_barrons-2024_alt.jpg');
	}

	.white-bg-left, .white-bg-hero {
		background-color: rgba(255, 255, 255, 0.9);
	}

	.white-bg-left {
		position: absolute;
		top: 0;
		left: -200%;
		width: 200%;
		height: 100%;
	}
	.btn{
		color: white;
	}
	p, h1, a{
		color: black;
		text-decoration: none;
	}
}

.fun-little-vertical-separator {
	display: block;
	width: 1px;
	height: 40px;
	background-color: #999;

	&.light {
		background-color: #BBB;
	}
}
